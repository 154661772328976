*{
    /*font-family: 'Glacial Indifference';*/
    /*font-family: "Abril Fatface", serif;*/
}

@media screen and (max-width: 1440px) and (min-width: 1280px) {
    body {
        zoom: 0.7; /* Pour Chrome/Safari */
        -moz-transform: scale(0.8); /* Pour Firefox */
        -moz-transform-origin: 0 0;
    }
}

@media screen and (max-width: 1300px) and (min-width: 768px) {
    body {
        zoom: 0.6;
        -moz-transform: scale(0.8);
        -moz-transform-origin: 0 0;

        .content-menu-desktop{
            max-width: 50%;
        }
    }
}

@media screen and (max-width: 767px) {
    body {
        zoom: 0; /* Réinitialise le zoom pour les appareils mobiles */
        -moz-transform: scale(1);
        -moz-transform-origin: 0 0;
    }

    .event-item{
        margin-top: 20%;
    }

    .content-header-event {
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 5%, rgba(252, 233, 255, 1) 24%, rgba(240, 197, 242, 1) 51%, rgba(212, 179, 254, 1) 91%);
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 0em;
        min-height: 30vh;
    }
}

.container{
    max-width: 100% !important;
    overflow: hidden;
}

body{
    max-width: 100%;
    padding: 0;
    margin: 0;
}

.html{
    font-size: 16px;
}

.content-header,
.content-carousel,
.slogan,
.financement,
.timeline,
.expert,
.step,
.footer {
    margin-bottom: 40px; /* Ajustez cette valeur selon vos besoins */
    position: relative;
    z-index: 0; /* Assurez-vous que ces sections sont en dessous */
}

@font-face {
    font-family: 'Glacial Indifference';
    src: url("../GlacialIndifference-Regular.otf") format('truetype');
    font-weight: normal;
    font-style: normal;
}

/************************COLORS************************/

.color-orange{
    color: #FFCD3B;
}

.dark-orange {
    color: #FFB325;
}
.color-black{
    color: #271350 !important;
}

.rainbow-color{
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(218, 200, 255, 0.61) 15%, rgba(210, 170, 247, 1) 35%, rgba(236, 191, 250, 1) 65%, rgba(251, 255, 113, 1) 100%, rgba(250, 255, 75, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.pink-color{
    color: #f943b1;
}

.violet-color{
    color: #dcb7fb !important;
}

.blue-color{
    color: #64FFF5;
}

.red-color{
    color: #FF7569;
}

.white-color{
    color: white !important;
}

.grey-color{
    color: #7C7C7C;
}

.dark-grey{
    color : #82898F;
}

.green-color{
    color: #67b567;
}

.dark-green{
    color: #009D15;
}

.light-green{
    color: #33D653
 }

.light-blue{
    color: #00D7ED;
}

.dark-blue{
    color: #564FFF;
}

.blue-color-o{
    color:  #83b4c5
}

/************************BASICS************************/

.w-30{
    width: 35%;
}

.m-l-0{
    margin-left: 0 !important;
}

.w-100{
    width: 100%;
}

.m-t-10{
    margin-top: 10%;
}

.m-b-10{
    margin-bottom: 10%;
}

.m-t-15{
    margin-top: 15%;
}

.m-t-5{
    margin-top: 5%;
}

.m-t-3{
    margin-top: 3%;
}

.m-l-10{
    margin-left: 10%;
}

.m-l-15{
    margin-left: 15%;
}


.m-b-O{
    margin-bottom: 0;
}

.d-flex{
    display: flex !important;
}

.light-f{
    font-weight: 200;
}

.p-t-5{
    padding-top: 5%;
}

/**************************FONT****************************/

@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Montserrat:wght@200&family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&display=swap');

/**************************HOME****************************/
.active-home {
    background-color: red;
    color: green;
}

.btn-investor {
    position: relative;
    border-radius: 4em;
    font-size: 22px;
    width: 100%;
    background-color: #dcb7fb;
    color: #271350;
    transition: 0.3s;
    font-family: 'Glacial Indifference';
}

.btn-investor:hover{
    color: #dcb7fb !important;
}

.small-title-offers{
color: #b6bcc3;
line-height: 15px;
}
.section{
height: 100vh;
}
.justify-content-between{
justify-content: space-between !important;
}

.btn{
background: none;
}

.btn:hover{
background: none;
}

.btn-menu-subscribe-h{
background: #FFCD3B;
height: clamp(0.5rem,3.5vw,4rem);
font-size: clamp(0rem,1.1vw,1.2rem);
border-radius: 4em;
color: #271350;
font-family: 'Montserrat', sans-serif;
font-weight: 600;
width: 120%;
}

.btn-menu-subscribe-i{
background: none;
height: clamp(0.5rem, 3vw, 4rem);
font-size: 20px;
border-radius: 4em;
color: #271350;
/* font-family: 'Montserrat', sans-serif; */
    font-weight: 600;
    /* padding-left: 50%; */
    font-family: 'Glacial Indifference';

}

.btn-menu-subscribe-c{
    background: #FF7569;
    height: clamp(0.5rem,3.5vw,4rem);
    font-size: clamp(0rem,1.1vw,1.2rem);
    border-radius: 4em;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    width: 120%;
}

button::before, button::after {
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    transition: 0.5s ease;
}

button{
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: .5s;
    z-index: 1;
}


button::before {
    top: -1em;
    left: -1em;
}

button::after {
    left: calc(100% + 1em);
    top: calc(100% + 1em);
}

button:hover::before, button:hover::after {
    height: 410px;
    width: 410px;
}

button:hover {
    color: white;
    background: #271350;
}

button:active {
    filter: brightness(.8);
}


.header{
    background: linear-gradient(#FFE394 , white);
}

.subtitle-header{
    margin-top: 5%;
    color: #271350;
    line-height: 1.3em;
    font-weight: 600;
    width: 45%;
    text-align: center;
    margin: 0 auto;
    font-size: clamp(1.5rem, 1.1vw, 1.2rem);
    margin-top: 5%;
    font-weight: 300;
    line-height: 33px;
    font-family: 'Glacial Indifference';
}

.btn-com-f:hover{
    background: #violet;
}

.fade {
opacity: 0;
transition: opacity 0.5s ease-in-out;
}

.fade.show {
opacity: 1;
}

.cookie{
width: 3em;
}

.welcome{
    align-items: center !important;
    display: grid;
    height: 80vh;
}

.form-s{
    margin: 0 auto;
    margin-top: 3%;
}

.pic-welcome{
    width: 80%;
    object-fit: contain;
    height: auto;
}

.content-text-welcome{
    max-width: 50%;
    align-self: end;
    margin-top: 5%;
}

.content-welcome{
    width: 90%;
    margin: 0 auto;
    align-items: center;
    margin-top: 5%;
}

.txt-welcome{
    width: 85%;
    font-size: clamp(1rem, 4vw, 1.3rem);
}

.content-icons{
    width: 8%;
    margin-top: 2% !important;
    font-size: 30px;
    margin-left: 20%;
}

.subtitle-subscribe{
    text-align: center;
    font-size: clamp(0rem,1.1vw,1.2rem);
    width: 40%;
    margin: 0 auto;
}

.input-footer{
    height: 60px;
    border: none;
    border-radius: 40px;
    margin-bottom: 0;
    padding-left: 3%;
    width: 22%;
    font-family: monospace;
    font-size: clamp(0rem,1.1vw,1.2rem);
    margin-left: 38%;
    margin-top: 3%;
}

.small-logo{
    padding-top: 3%;
    width: 60%;
}

.content-footer{
    width: 80%;
    justify-content: space-between;
    text-align: start;
    margin-left: 10%;
    margin-top: 4%;
    font-size: clamp(0rem,1.1vw,1.2rem);
}

.block-menu-footer{
    list-style: none;
    padding-left: 0;
    line-height: 35px;
    font-weight: 200;
}

.icon-arrow{
    font-size: 1.5rem;
    align-self: center;
}

.icons{
    color: black;
}

.icons:hover{
    color: #FF1E73;
}

.btn-welcome{
    background: #FFCD3B;
    z-index: 2;
    height: 3.5rem;
    letter-spacing: 0px;
    align-items: center;
    border-radius: 45px;
    padding-left: 5%;
    padding-right: 5%;
    font-size: clamp(1rem, 4vw, 1.3rem);
    color: black;
}

.hr-footer{
    width: 80%;
    margin-top: 2%;
    -webkit-text-fill-color: transparent;
    color: transparent;
    border: none;
    height: 1px;
    background: linear-gradient(90deg, rgba(212, 168, 255, 1) 21%, rgba(251, 166, 242, 1) 58%, rgba(218, 205, 255, 1) 100%);
    margin-bottom: 3%;
}

.text-footer{
    font-size: clamp(0rem,1.1vw,0.9rem);
    width: 80%;
    margin: 0 auto;
    padding-top: 1%;
    font-weight: 200;
}

/****************WELCOME****************/
.input-container {
    padding: 0.3rem;
    gap: 0.3rem;
    padding-right: 0;
    margin-top: 0;
    height: 50px;
    margin-bottom: 2%;
}

.input-container input {
    border-radius: 45px 0 0 45px;
    width: 42em;
    flex-basis: 40%;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%) !important;
    display: flex !important;
    text-align: center;
    font-size: clamp(0rem, 1.1vw, 1.2rem);
    height: 3rem;
    padding-left: 3%;
    padding-right: 3%;
    border: 1px solid #F0F0F0 !important;
    border-right: none;
    margin-right: 0;
    margin-left: 0%;
    margin-top: 0%;
}

.input-container input:focus {
    outline: none;
}

.input-container button {
    flex-basis: 5%;
    background: rgb(255, 205, 59);
    font-weight: 900;
    letter-spacing: 0.3rem;
    text-transform: uppercase;
    color: white;
    border: none;
    border-radius: 0px 45px 45px 0px;
    transition: all 0.2s ease-in-out 0s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: none;
    cursor: pointer;
}

.input-container button:hover {
    background: #271350
}

.icons{
    font-size: clamp(1.3rem, 4vw, 2rem);
}

.input-welcome{
    height: 60px;
    border: navajowhite;
    border-radius: 40px;
    width: 30%;
    font-family: monospace;
    margin-top: 5%;
    box-shadow: 0 0 10px #ffe7a0;
    padding-left: 5%;
}

.title-welcome{
    margin-bottom: 0;
    font-size: clamp(2.5rem, 3vw, 4rem);
}
.button-container-investors{
    max-width: 100%;
    width: 80%;
    grid-row: 2;
}

.button-container {
    display: flex;
    padding: 20px;
    text-align: center;
    margin-left: 35%;
}

.text-orders{
    text-align: left;
    margin-left: 10%;
    line-height: 0;
    align-self: center;
    margin-top: 5%;
    font-family: 'Montserrat', sans-serif;
}

.mix-btn{
    position: relative;
    border-radius: 4em;
    font-size: 22px;
    border: none;
    transition: 0.3s;
    width: 45% !important;
}

.icon-btn-menu{
    width: 70px;
    margin-left: 3%;
    margin-right: 5%;
}

.icon-btn-menu-c{
    width: 70px;
    margin-left: 3%;
    margin-right: 0%;
}

.icon-btn-menu-i{
    width: 70px;
    margin-left: 3%;
}

.selected-button {
    background: #271350;
    color: white;
    font-size: 22px;
    align-items: center;
    display: flex;
    width: 32%;
    text-align: center;
    justify-content: center;
    font-family: 'Glacial Indifference', serif;
}

.selected-button:hover {
background: #271350;
color: white;
}

.Content-box{
width: 55%;
}

.txt-solutions-c{
text-align: center;
font-size: clamp(0rem,1.1vw,1.2rem);
/*font-family: 'GilroyLight', sans-serif;*/
    font-family: 'Montserrat', sans-serif;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 35px;
    margin-top: 0;
    color: #271350;
}

.txt-solutions{
    margin-left: 5%;
    text-align: left;
    font-size: clamp(0rem,1.1vw,1.2rem);
    font-family: 'Montserrat', sans-serif;
    /*font-family: 'GilroyLight', sans-serif;*/
    width: 50%;
    line-height: 35px;
    color: #271350;
}

.deselected-button {
    background: #FFF7E2;
    z-index: 1;

    .mix-btn1{
        left: -10%;
    }
}

.mix-btn-deselected {
    align-items: center;
    display: flex;
}

.tittle-offers-3{
    color: #9A41FF;
    font-size: clamp(1.4rem,7vw,0.5rem);
    margin-bottom: 0;
}

.tittle-offers-2{
    color: #00D7ED;
    font-size: clamp(1.4rem,7vw,0.5rem);
    margin-bottom: 0;
}

.tittle-offers-1{
    color: #33D653;
    font-size: clamp(1.4rem,7vw,0.5rem);
    margin-bottom: 0;
}

.menu-header-bg {
    /*background: linear-gradient(rgba(255, 205, 59, 0.6) -5%, white);*/
}

.speak{
    width: 400px;
    margin-top: 25%;
}

#cookiePopup{
position: fixed;
width: 25%;
text-align: center;
background: white;
padding: 2% 2% 2% 2%;
font-family: 'Monserrat', sans-serif;
letter-spacing: 1px;
font-weight: 100;
justify-content: flex-end !important;
bottom: 2%;
z-index: 5;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
margin-left: 2%;
border-radius: 0.8em;
}

.content-button-cookies{
justify-content: space-between;
}

.accept-cookie{
    background:#F0E7FF;
    padding: 2%;
    margin-right: 10%;
    width: 35%;
    margin-top: 5%;
    font-size: clamp(0rem,1.1vw,1.2rem);
}

.refuse-cookie{
    background:#F0E7FF;
    padding: 2%;
    width: 35%;
    margin-top: 5%;
    font-size: clamp(0rem,1.1vw,1.2rem);
}

.accept-cookie:hover{
    color: white !important;
}

.refuse-cookie:hover{
    color: white !important;
}


/****************************HEADER*********************************/

.App-header{
    margin-top: -2%;
    width: 100%;
}

.logo{
    width: clamp(10rem,1vw,0.1rem);
}

.content-menu-mobile{
    display: flex;
    margin-top: 2%;
    align-items: center;
    text-align: center;
    width: 80%;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
}
.menu{
    color: white;
    list-style: none;
    display: flex;
    justify-content: space-between;
    text-align: center;
    font-size: 1.5em;
    width: 30%;
}

.title{
    color: black;
    font-size: 2.3em;
}

.join-us{
    background: none;
    font-size: clamp(0rem,1.1vw,1.2rem);
    padding-left: 1.5em;
    height: 2.5em;
    padding-right: 1.5em;
    border: none;
    color: #271350;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.join-us-consultant{
    background: none;
    font-size: clamp(0rem,1.1vw,1.2rem);
    padding-left: 1.5em;
    height: 2.5em;
    padding-right: 1.5em;
    border: none;
    color: #271350;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.join-us-investors{
    background: none;
    font-size: clamp(0rem,1.1vw,1.2rem);
    padding-left: 1.5em;
    height: 2.5em;
    padding-right: 1.5em;
    border: none;
    color: #271350;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.join-us:hover{
    color: #FFCD3B;
    background: none;
}

.join-us-investors:hover{
    color: #9A41FF;
    background: none;
}

.join-us-consultant:hover{
    color: #FF7569;
    background: none;
}

.contact:hover{
    background: #FFCD3B;
    color: black;
}

.contact{
    color: black;
    font-size: 1.3em;
    border: 2px solid black;
    background: none;
    border-radius: 0.75em;
    padding: 0.7em 1.5em;
}

.buttons-menu{
    display: flex;
    justify-content: space-between;
}

.bg{
    border-radius: 1.875rem;
}

.small-txt-offers{
    font-size: clamp(0rem,1.1vw,1.2rem);
    font-weight: 400;
    color: #271350;
    line-height: initial;
}

.content-offers{
    margin-top: 10%;
}

.subtitle-offers{
    margin-left: 15%;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    /*font-family: 'GilroyLight', sans-serif;*/
    font-weight: 700;
}
.content-block-offers{
    justify-content: space-between;
    width: 80%;
    display: flex;
    margin-left: 10%;
    margin-top: 5%;
}

.block-tarif{
    width: 30%;
    border-radius: 30px;
    border: 1px solid #f1f4f8;
    height: auto;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
    padding-top: 2%;
    padding-bottom: 2%;

}

.yellow-check{
    width: 30px;
    object-fit: contain;
    padding-right: 5%;
    align-self: flex-start;
    margin-top: 5%;
}

.block-1{
    width: 5em;
    height: 5em;
    border-radius: 15px;
    margin-left: 5%;
    margin-top: 5%;
    background: #E4FFDC;
}

.block-2{
    width: 5em;
    height: 5em;
    border-radius: 15px;
    margin-left: 5%;
    margin-top: 5%;
    background: #B6FEFF;
}

.block-3{
    width: 5em;
    height: 5em;
    border-radius: 15px;
    margin-left: 5%;
    margin-top: 5%;
    background: #EFDCFF;
}

.content-link-menu{
    width: 100%;
}

.content-community{
    width: 70%;
    margin-left: 15%;
    justify-content: space-between;
    padding-top: 3%;
    padding-bottom: 3%;
}

.text-com{
    width: 50%;
    align-self: center;
}

.section-community{
    background: #f1f4f8;
    margin-top: 8%;
}

.title-com{
    text-align: left;
    letter-spacing: 3px;
    color: #271350;
    width: 105%;
    margin-top: 0;
    font-size: clamp(1rem,2vw,3rem);
    font-weight: 800;
}

.txt-com{
    text-align: left;
    font-size: clamp(0rem,1.1vw,1.2rem);
    font-family: 'Montserrat', sans-serif;
    /*font-family: 'GilroyLight', sans-serif;*/
    line-height: 35px;
    font-weight: 800;
    color: #271350;
}

.content-img-com{
    width: 30%;
}

.pic-header{
    object-fit: contain;
    margin-left: -40%;
    width: clamp(200px, 40vw, 521px);
}

.content-offers-price{
    margin-left: 5%;
    text-align: left;
    line-height: 5px;
    margin-top: 2%;
}

.btn-com{
    background: #FFCD3B;
    color: #271350;
    letter-spacing: 0px;
    width: 370px !important;
    height: 3em;
    border-radius: 4em;
    font-size: 22px;
    border: none;
    margin-top: 5%;
}

.content-title-offers-block1{
    height: 3em;
    padding: 1em;
    width: 3em;
}

.content-title-offers-block2{
    height: 3em;
    background: #5AE8E8 ;
    padding: 1em;
    border-radius: 25px 25px 0 0;
}

.content-title-offers-block3{
    height: 3em;
    background: #B966FF;
    padding: 1em;
    border-radius: 25px 25px 0 0;
}

.title-carousel{
    text-align: center;
    margin-top: 5%;
    font-size: 1.5em;
    width: 40%;
    margin-left: 30%;
    color: #271350;
}

.content-carousel{
    width: 80%;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 7%;
    margin-top: 5%;
}

.seven{
    font-size: 4.5em;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(218, 200, 255, 0.61) 15%, rgba(210, 170, 247, 1) 35%, rgba(236, 191, 250, 1) 65%, rgba(251, 255, 113, 1) 100%, rgba(250, 255, 75, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    font-weight: 600;
}

.img-carrousel{
    width: 10%;
    object-fit: contain;

}

.title-offers{
    text-align: center;
    font-size: clamp(1rem,2vw,3rem);
    margin-top: 0;
    margin-bottom: 3%;
    color: #271350 !important;
    font-weight: 800;
}

.mini-title-offers{
    text-align: center;
    font-size: 20px;
    color: #FFCD3B;
}

.mini-title-solution{
    text-align: left;
    margin-bottom: 0;
    font-size: 20px;
    margin-left: 10%;
}

.bitmoji{
    width: 90px;
    margin-left: -20px;
    bottom: 0;
    margin-top: -18px;
}

.price{
    font-size: 22px;
    margin-bottom: 0;
    font-family: 'Montserrat', sans-serif;
    /*font-family: 'GilroyLight', sans-serif;*/
    font-weight: 700;
    color: #271350;
}

.text-offers{
    margin-left: 5%;
    align-self: center;
    margin-top: 5%;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    /*font-family: 'GilroyLight', sans-serif;*/
    margin-bottom: 0;
}

.subtitle{
    width: 60%;
    font-size: 25px;
    text-align: center;
    letter-spacing: 1px;
    margin-top: 3%;
    font-family: 'Montserrat', sans-serif;
    /*font-family: 'GilroyLight', sans-serif;*/
    margin-left: 20%;
    color: #444;
}

.button-header{
    color: white;
    font-size: 1.5em;
    border-radius: 50px;
    background: rgb(82, 9, 121);
    border: none;
    width: 10%;
    height: 2.5em;
    margin-left: 45%;
    margin-bottom: 3%;
    margin-top: 3%;
}

.pictures{
    display: flex;
    width: 90%;
    justify-content: space-between;
    margin-left: 5%;
    margin-top: -10%;
    align-items: end;
}

.content-card-s{
    justify-content: space-between;
    width: 30%;
    margin-left: 35%;
    margin-top: 3%;
}

.card-s{
    width: 30%;
    height: auto;
    background: white;
    box-shadow: 0 0 10px #ffe7a0;
    border-radius: 13px;
}

.pic-s{
    width: 60%;
    padding-top: 10%;
    margin-left: 20%;
}

.p-s{
    text-align: center;
    margin-top: 0%;
    font-size: clamp(0rem,1.1vw,1.2rem);
    font-family: 'Montserrat', sans-serif;
    /*font-family: 'GilroyLight', sans-serif;*/
}

.pic{
    height: 22em;
    object-fit: cover;
}

.pic-1{
    margin-top: -50%;
    margin-left: -10%;
}

.pic-2{
    margin-top: -50%;
    margin-left: -20%;

}

.pic-4{
    margin-left: -25%;
}

.pic-3{
    margin-left: 30%;
}

.icon{
    width: 10em;
    text-align: center;
    margin-top: 10%;
}

.content_solution{
    width: 76%;
    margin-left: 12%;
}

button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    font-family: inherit;
    font-size: 15px;
}
.learn-more-form{
    background: #FFCD3B;
    width: 14% !important;
    margin-left: 43% !important;
    margin-bottom: 5% !important;
    margin-top: 2%;
    height: 70px;
    border-radius: 40px;
    font-size: 20px;
}

button.learn-more {
    width: 10%;
    margin-left: 45%;
    margin-bottom: 3%;
    font-weight: 600;
    color: #382b22;
    text-transform: uppercase;
    padding: 1.25em 2em;
    background: #FFE085;
    border: 2px solid #FFCD3B;
    border-radius: 0.75em;
    transform-style: preserve-3d;
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms ease-out;
    margin-top: 2%;
}

button.learn-more::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #FFF1C8;
    border-radius: inherit;
    -webkit-box-shadow: 0 0 0 2px #FFCD3B, 0 0.625em 0 0 #FFF1C8;
    box-shadow: 0 0 0 2px #FFCD3B, 0 0.625em 0 0 #FFF1C8;
    -webkit-transform: translate3d(0, 0.75em, -1em);
    transform: translate3d(0, 0.75em, -1em);
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1), -webkit-transform 150ms cubic-bezier(0, 0, 0.58, 1), -webkit-box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
}

button.learn-more:hover {
    background: #FFF1C8;
    -webkit-transform: translate(0, 0.25em);
    transform: translate(0, 0.25em);
}

button.learn-more:hover::before {
    -webkit-box-shadow: 0 0 0 2px #FFCD3B, 0 0.5em 0 0 #FFF1C8;
    box-shadow: 0 0 0 2px #FFCD3B, 0 0.5em 0 0 #FFF1C8;
    -webkit-transform: translate3d(0, 0.5em, -1em);
    transform: translate3d(0, 0.5em, -1em);
}

button.learn-more:active {
    background: #FFF1C8;
    -webkit-transform: translate(0em, 0.75em);
    transform: translate(0em, 0.75em);
}

button.learn-more:active::before {
    -webkit-box-shadow: 0 0 0 2px #FFCD3B, 0 0 #FFF1C8;
    box-shadow: 0 0 0 2px #FFCD3B, 0 0 #FFF1C8;
    -webkit-transform: translate3d(0, 0, -1em);
    transform: translate3d(0, 0, -1em);
}

/**************************PARTNERS*************************/
.content-solutions{
    font-size: 1.6em;
    color: black;
    font-weight: 800;
    background: #FFF7DA;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-top: 10%;
}

.solutions{
    box-sizing: border-box;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.content-solutions-c{
    font-size: 1.6em;
    color: black;
    width: 70%;
    margin-left: 15%;
    margin-top: 10%;
    font-weight: 800;
    background: #FFEEE7;
    padding-top: 3%;
    padding-bottom: 3%;
}

.hands{
    width: 250px;
}

.gift{
    object-fit: contain;
    z-index: 2;
    position: absolute;
    margin-left: 60%;
    margin-top: -6%;
    width: clamp(200px, 30vw, 521px);
    height: clamp(200px, 36vw, 520px);
}


.title-financement{
    font-size: 2em;
    margin-top: 0;
}

.content-f{
    width: 55%;
    margin: 0 auto;
    align-items: end;
    margin-top: 5%;
}

.marge-i-2{
    margin-left: -600%;
}

.marge-i-3{
    margin-left: 400%;
}

.marge-i-5{
    margin-left: 600%;
}

.text-btn-header{
    line-height: 26px;
    font-weight: 400;
    font-size: 22px;
    font-weight: 500;
    font-family: 'Glacial Indifference';
}

.btn-com-f:hover{
    background-color: #dcb7fb;
}

.border-icons-f{
font-size: 30px;
border-radius: 60%;
box-shadow: 0 0 10px rgba(231, 195, 247, 0.8);
margin-bottom: 70%;
padding: 15px 20px 15px 20px !important;
}

.btn-boss{
    color: #dcb7fb;
    font-weight: normal;
}

.btn-boss:hover {
    background-color: #dcb7fb;
    color: #271350;
}

.content-icons-f{
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
margin-top: 10%;
}

.text-financement{
width: 90%;
margin: 0 auto;
margin-top: 5%;
text-align: center;
font-family: 'Glacial Indifference';
font-size: 22px;
line-height: 30px;
}

.content-items{
margin: 0 auto;
justify-content: space-between;
width: 90%;
}

.content-join{
height: 25em;
background: #9A41FF;
width: 100%;
margin-top: 10%;
}

.title-wework{
margin-top: -13%;
color: white;
font-size: 2em;
text-align: left;
margin-left: 25%;
width: 25%;
}

.item{
width: 30%;
margin-top: 5%;
}

.content-subscribe{
margin: 0 auto;
padding: 5em;
border-radius: 1.85em;
margin-top: 5%;
padding-top: 0;
margin-bottom: 2%;
}

.title-subscribe{
color: #271350;
font-weight: 800;
text-align: center;
padding-top: 0px;
letter-spacing: 3px;
font-size: clamp(1rem,2vw,3rem);
}

.title-hidden{
font-family: 'Fuggles', cursive;
color: #FFE599;
font-size: 15em;
z-index: -4;
position: sticky;
text-align: center;
margin-top: 15%;
margin-bottom: -25%;
padding-top: 0%;
}

.input{
width: 80%;
margin-left: 10%;
height: 3em;
border-radius: 1em;
border: 2px solid black;
}

.text-form{
width: 55%;
font-size: 1.3em;
text-align: center;
letter-spacing: 1px;
font-weight: 100;
margin: 0 auto;
}

/**************************Solution*************************/

.content-advantages{
    font-size: 1.6em;
    color: black;
    /* width: 70%; */
    font-weight: 800;
    background: #FFF7DA;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 70%;
    margin: 0 auto;
    margin-top: 10%;
}

.title-advantages{
    font-size: 2.5em;
    text-align: left;
    padding-top: 6%;
    margin-left: 15%;
    font-family: 'Montserrat', sans-serif;
    /*font-family: 'GilroyLight', sans-serif;*/
}

.text-advantage{
    margin-left: 5%;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    /*font-family: 'GilroyLight', sans-serif;*/
    font-weight: 700;
}

.check{
    width: 2em;
    object-fit: contain;
}

.block-advantage{;
    margin-left: 15%;
    width: 70%;
}

.button-advantage{
    color: white;
    font-size: 1.5em;
    border-radius: 50px;
    background: rgb(82, 9, 121);
    border: none;
    width: 15%;
    height: 2.5em;
    margin-left: 45%;
    margin-bottom: 3%;
    margin-top: 3%;
}

.emoji-item{
    font-size: 3em;
    margin-top: 0;
    margin-bottom: 0;
}

.number{
    color: #FFCD3B;
    font-size: 2em;
}

.text-solution{
    font-size: 20px;
    text-align: center;
    letter-spacing: 1px;
    margin-top: 2%;
    font-weight: 100;
}
/**************************Solution*************************/
.pic_solution{
    width: clamp(200px, 30vw, 500px);
    height: clamp(200px, 30vw, 500px);
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 40px;
}

.text-solution-right{
    margin-left: 10%;
    font-size: clamp(0rem,1.1vw,1.2rem);
    font-family: 'Montserrat', sans-serif;
    /*font-family: 'GilroyLight', sans-serif;*/
    font-weight: 700;
    width: 90%;
    line-height: 30px;
    color: #271350;;
}

.content-s-right{
    margin-left: 10%;
}

.text-solution-left{
    font-size: clamp(0rem,1.1vw,1.2rem);
    font-family: 'Montserrat', sans-serif;
    /*font-family: 'GilroyLight', sans-serif;*/
    font-weight: 700;
    width: 75%;
    line-height: 30px;
    color: #271350;
}

.content-block-solution{
    align-self: center;
}

.content-advantage{
    transform-origin: center;
    margin-top: 5% !important;
    background: #f1f4f8;
    padding-bottom: 10%;
    margin: 0;
    justify-content: center;
    width: auto;
}

.small-content-advantage{
    padding-top: 5%;
    width: 70%;
    margin: 0 auto;
}
.btn-cta-h{
    margin-top: 5%;
    background: #FFCD3B;
    z-index: 2;
    color: #271350;
    letter-spacing: 0px;
    align-items: center;
    position: relative;
    width: auto;
    height: 3em;
    border-radius: 4em;
    font-size: clamp(1.3rem,7vw,1rem);
    border: none;
    transition: 0.3s;
    padding-left: 3%;
    padding-right: 3%;
}

.btn-cta-h:hover{
    background: #271350;
    color: white;
}

.btn-cta-end{
    background: #FFCD3B;
    color: #271350;
    letter-spacing: 0px;
    height: 3em;
    border-radius: 4em;
    font-size: clamp(1.3rem,7vw,1rem);
    border: none;
    margin-left: 5%;
    margin-top: 1%;
    width: auto;
    padding-left: 3%;
    padding-right: 3%;
}

.btn-cta-end:hover{
    background: #271350;
    color: white;
}

.btn-offers:hover{
    background: #271350;
    color: white;
}

.btn-cta-end-c{
    background: #FF7569;
    color: white;
    letter-spacing: 0px;
    width: 270px !important;
    height: 3em;
    border-radius: 4em;
    font-size: 22px;
    border: none;
    margin-top: 1%;
}

.pic-advantage{
    height: 35em;
    width: 40em;
    object-fit: cover;
    margin-left: 10%;
    border-radius: 1.5em;
}

.title-advantage{
    color: white;
    margin-left: 5%;
    font-size: 2.7em;
    margin-bottom: 5%;
}

.block-ad-1{
    background: rgba(0,212,255,1) 0%;
    height: 15em;
    width: 20em;
    border-radius: 1.5em;
    margin-left: 5%;
}

.block-ad-2{
    background: rgba(82,9,121,1) 100%;
    height: 15em;
    width: 20em;
    margin-top: 5em;
    border-radius: 1.5em;
    margin-left: 5%;
}

.content-block-adv {
    align-items: end;
    justify-content: space-between;
}

.content-colored-block{
    margin-left: 10em;
}

.text-blue{
    margin-top: 0;
    color: white;
    padding-top: 25%;
    padding-left: 5%;
    padding-right: 5%;
    font-weight: 800;
    font-size: 1.2em;
}

.title-solution{
    font-size: 3em;
    margin-top: 0;
}

.big-title-solution{
    align-self: center;
    letter-spacing: 0;
    font-size: clamp(1rem,2vw,3rem);
    margin-left: 10%;
    margin-top: 2%;
    color: #271350;
    font-weight: 800;
}

.big-title-solution-left{
    margin-left: 0% !important;
}

.block-solution-1{
    width: 10em;
    height: 10em;
    border-radius: 2em;
    margin-top: 3%;
    background: rgba(36,191,184,1);
}

.block-solution-2{
    width: 10em;
    height: 10em;
    border-radius: 2em;
    margin-top: 3%;
    background: rgba(146,142,213,1);
}

.block-solution-3{
    width: 10em;
    height: 10em;
    border-radius: 2em;
    margin-top: 3%;
    background: rgba(0,212,255,1);
}

.block-solution-4{
    width: 10em;
    height: 10em;
    border-radius: 2em;
    margin-top: 3%;
    background: rgba(82,9,121,1) ;
}

.hr{
    width: 80%;
    color: #f1f4f8;
    border: 1px solid;
}

.title-block-solution{
    font-size: 2.5em;
    margin-bottom: 0;
    padding-top: 10%;
    margin-top: 0;
    text-align: center;
}

.small-text-block-solution{
    font-size: 1.5em;
    padding-left: 5%;
    padding-right: 5%;
}

.small-text-block-solution{
    text-align: center;
}

.text-center{
    text-align: center !important;
}

.text-left{
    text-align: left !important;
}
/************************MEETINGS****************************/

.section-meeting{
    margin-top: 5%;
    font-family: 'Roboto', sans-serif;
}



.meeting{

}
.title-meetings{
    color: white;
    margin-left: 5%;
    font-size: 2.7em;
    margin-bottom: 3%;
}

.title-meeting-end{
    margin-right: 5%;
    font-size: 1.5em;
    margin-bottom: 3%;
    color: rgba(36,191,184,1);
    align-self: flex-end;
}

.subtitle-meeting{
    color: white;
    margin-left: 5%;
    width: 60%;
    font-size: 1.5em;
}

.block-m{
    width: 100%;
    justify-content: space-between;
    margin-top: -1%;
    opacity: 11;
    background-color: #27242D;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 1em;
    border-radius: 0 0 1em 1em;
    padding-top: 1em;
}

.m{
    height: 15em;
    object-fit: cover;
    width: 23.5em;
}

.content-arrow{
    align-self: center;
    margin-left: 5%;
}

.btn-arrow{
    background: none;
    border: none;
}

.arrow{
    width: 8em;
}
.title-meeting{
    font-size: 1.5em;
    margin-left: 5%;
    color: white;
}

.text-meeting{
    margin-left: 5%;
    margin-top: -3%;
    font-family: 'Roboto', sans-serif;
    color: white;
    font-size: 1.3em;
    padding-right: 7%;
}

.meeting-date{
    color: rgba(36,191,184,1);
    font-weight: 800;
    margin-left: 5%;
    letter-spacing: 2px;
    margin-top: 10%;
}

.content-m{
    justify-content: space-between;
    display: flex;
    width: 95%;
    margin-left: 5%;
    margin-top: 5%;

}



.tags-meeting{
    display: flex;
    margin-top: 3%;
    width: 30%;
    margin-left: 5%;
}

.tag{
    margin-right: 5%;
    font-size: 1.5em;
    color: white;
    border: 2px solid rgba(36,191,184,1);
    padding: 0.5em;
    font-weight: bold;
    border-radius: 3em;
    padding-left: 1em;
    padding-right: 1em;
}

/************************Speaker****************************/

.title-speaker{
    color: white;
    margin-left: 5%;
    font-size: 2.7em;
    margin-bottom: 3%;
}

/************************Footer****************************/

.section-footer{
    text-align: left;
    color: white;
}

.meeting-btn{
    margin-left: 5%;
    color: white;
    font-size: 1.5em;
    border-radius: 50px;
    padding-left: 1.5em;
    padding-right: 1.5em;
    background: rgba(36,191,184,1);
    border: none;
    width: 10%;
    height: 2.5em;
    margin-bottom: 3%;
    margin-top: 5%;
}

.title-header{
    color: white;
    font-size: 4.5em;
    width: 80%;
    text-align: center;
    margin-left: 10%;
    margin-top: 10%;
}

.subtitle-footer{
    color: white;
    width: 60%;
    font-size: 1.5em;
}

.start-advanture-btn{
    color: white;
    font-size: 1.5em;
    border-radius: 50px;
    padding-left: 1.5em;
    padding-right: 1.5em;
    background: rgb(82, 9, 121);
    border: none;
    height: 2.5em;
    margin-left: 45%;
    margin-bottom: 3%;
    margin-top: 3%;
}

.colonne {
    width: 25%;
    float: left;
    padding: 10px;
    box-sizing: border-box;
}

.colonne h3 {
    font-size: 1em;
    margin-bottom: 10px;
    margin-top: 20%;
}

.colonne ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.colonne ul li {
    margin-bottom: 10%;
    font-size: 1.4em;
}

.colonne ul li a {
    color: white;
    text-decoration: none;
}

.colonne ul li a:hover {
    text-decoration: underline;
}

.footer{
    width: 100%;
    background: #271350;
    margin-top: 10%;
    bottom: 0;
    padding-top: 1%;
    margin-bottom: 0;
}


.underline {
    position: relative;
    padding-bottom: 15px;
    display: inline-block;
    border-bottom: 1px solid transparent;
}

.underline::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background: linear-gradient(90deg, rgba(212, 168, 255, 1) 21%, rgba(251, 166, 242, 1) 58%, rgba(218, 205, 255, 1) 100%); /* Dégradé */
    z-index: 1;
}

/************************Concept****************************/

.section-concept{
    width: 100%;
    margin-top: 5%;
}

.big-title-concept{
    font-size: 1.3em;
    font-weight: 100;
    padding-left: 0.8em;
    padding-top: 2em;
    padding-right: 0.8em;
    padding-bottom: 0.8em;
}

.emoji-item-concept{
    font-size: 1.8em;
    margin: auto;
    margin-top: 0.3em;
}

.emoji-item-concept-title{
    font-size: 1.6em;
    margin-left: 3%;
}

.emoji-item-form-subscribe{
    font-size: 5em;
    margin-top: 0;
}

.learn-more-concept{
    margin-top: 5% !important;
}

.searchbar{
    width: 50em;
    border-radius: 1em 1em 0 0;
    height: 2em;
    position: relative;
    object-fit: revert;
}

.meeting{
    width: 90%;
    height: 30em;
    object-fit: cover;
    border-radius: 1em;
    margin-top: -3%;
    object-position: top;
}

.emoji-item-form{
    margin-top: 0;
    margin-bottom: 0;
    font-size: 6em;
    margin-top: 0;
    margin-bottom: 0;
}

.block-text-meeting{
    width: 79%;
    margin-left: 10%;
    display: flex !important;
    justify-content: space-between;
    margin-top: 4%;
}

.mini-text-concept{
    width: 215px;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    /*font-family: 'GilroyLight', sans-serif;*/
    font-weight: 700;
    text-align: center;
    margin: 0 auto;
    background: white;
    padding: 5%;
    border-radius: 20px;
    margin-top: -5%;
}

.pic-concept{
    width: 50%;
}

.block-concept{
    background: white;
    height: 14em;
    border-radius: 1.85em;
    border: 3px solid black;
    margin-top: 5% !important;
    margin: 0 auto;
    width: 40%;
}

.block-img-meeting{
    width: 70%;
    align-self: center;
}

.title-concept{
    color: black;
    font-weight: 600;
    font-size: 45px;
}

.text-concept{
    color: #271350;
    width: 56%;
    text-align: center;
    margin-left: 22%;
    letter-spacing: 1px;
    margin-top: 0;
    font-size: clamp(0rem,1.1vw,1.2rem);
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    line-height: 40px;
}

.btn-cta-i{
    margin-top: 5%;
    background: #9A41FF;
    z-index: 2;
    color: white;
    font-size: 22px;
    letter-spacing: 0px;
    align-items: center;
    position: relative;
    width: 370px !important;
    height: 3em;
    border-radius: 4em;
    font-size: 22px;
    border: none;
    transition: 0.3s;
}

.content-adv-c{
    transform-origin: center;
    margin-top: 5%;
    background: #f1f4f8;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 5%;
    padding-bottom: 10%;
}

.btn-cta-c{
    margin-top: 5%;
    background: #FF7569;
    z-index: 2;
    color: white;
    font-size: 22px;
    letter-spacing: 0px;
    align-items: center;
    position: relative;
    width: 370px !important;
    height: 3em;
    border-radius: 4em;
    font-size: 22px;
    border: none;
    transition: 0.3s;
}

.content-legal-notice{
    width: 70%;
    margin: 0 auto;
    line-height: 30px;
    font-size: clamp(0rem,2vw,1.2rem);
    color: #271350;
}

.title-notice{
    margin-left: 15%;
    font-size: clamp(2.3rem,2vw,1.2rem);
    color: #271350;
    font-weight: 900;
    margin-top: 5%;
}

.block-legal-notice{
    height: auto;
}

.number-mt{
    color: rgba(36,191,184,1);
}

.btn-concept{
    color: rgba(36,191,184,1);
    font-size: 1.3em;
    border-radius: 50px;
    border: 3px solid rgba(36,191,184,1);
    background: none;
    height: 2em;
    margin-bottom: 3%;
    margin-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
}

.second-m{
    margin-top: 1em;
}

.block-m{
    width: 48em;
}

.ml1{
    margin-right: 1em;
}

.block-img-m{

}

.tag-meeting{
    color: white;
    border-radius: 2em;
    background: #27242D;
    padding-left: 2%;
    padding-right: 2%;
    width: 5%;
    margin-top: -3%;
    position: absolute;
    margin-left: 2%;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
}

.drow{
    margin-left: -4%;
    width: 20em;
    margin-top: 5%;
}

.block-card{
    margin-right: 5%;
    background-color: white;
    border-radius: 1.5em;
}

.meeting-img{
    width: 100%;
    object-fit: cover;
    height: 15em;
}

.input-group {
    display: flex;
    align-items: center;
    margin-left: 35%;
}

.success-img{
    height: 45em;
    width: 35em;
    object-fit: cover;
    border-radius: 1.875rem;
}

.input-contact {
    height: 55px;
    border: navajowhite;
    border-radius: 40px;
    margin-bottom: 0;
    padding-left: 3%;
    width: 25%;
    font-family: monospace;
    margin: 0 auto;
    margin-top: 0px;
    margin-top: 2%;
    box-shadow: 0 0 10px #ffe7a0;
    font-size: clamp(0rem,1.1vw,1.2rem);
}

.logo-s{
    padding: 1.8%;
    border-radius: 30px;
    background: linear-gradient(#FFD37A -21%,#FFFBDF 95%);
    border: 3px solid #462292;
}

.input-contact-select {
    border: 0;
    border-radius: 4em;
    margin-left: 35%;
    margin-bottom: -2em;
    padding-left: 2%;
    width: 30%;
    height: 5em;
    font-family: monospace;
    margin-top: 3%;
    background: white;
}

.valueStartupName{
    margin-top: 3.8em;
}

.input-description{
    border: 3px solid black;
    border-radius: 1.5em;
    margin-left: 10%;
    margin-bottom: 5%;
    padding-left: 2%;
    padding-top: 5%;
    width: 80%;
    height: 8em;
    margin-top: 2em;
}

.form{
    width: 100%;
}

.select-wrapper {
    position: relative;
    display: inline-block;
    width: 83%;
    height: 5em;
    overflow: hidden;
    border: 3px solid black;
    border-radius: 1.5em;
    background: url("assets/arrow.png") no-repeat right center;
}

.button--submit {
    min-height: 50px;
    padding: 0.5em 1em;
    border: none;
    border-radius: 0 6px 6px 0;
    background-color: rgb(82, 9, 121);
    color: #fff;
    font-size: 1.3em;
    cursor: pointer;
    transition: background-color .3s ease-in-out;
    width: 15%;
    text-align: center;
}

.button--submit:hover {
    background-color: #5e5dcd;
}

.input:focus, .input:focus-visible {
    border-color: #3898EC;
    outline: none;
}

.title-solution{
    font-size: 35px;
    margin-bottom: 3%;
    font-family: 'Rowdies', cursive;
}

/************* VISIO *************/

.content-visio{
    width: 90%;
    margin-left: 5%;
    margin-top: 15%;
}

.viso-img{
    width: 45%;
    border-radius: 1.5em;
    border: 3px solid black;
    margin-left: 5%;
}

.title-step{
    margin-left: 5%;
    text-align: left;
    font-size: clamp(1rem,2vw,3rem);
    color: #9A41FF;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 800;
}

.content-main{
    width: 100%;
    padding-bottom: 5%;
    margin-top: 5%;
    padding-top: 5%;
}



.text-section{
    width: 60%;
    text-align: center;
    margin: 0 auto;
}

.title-step-c{
    text-align: center;
    font-size: clamp(1rem,2vw,3rem);
    color: black;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 800;
}


.subtitle-step{
    width: 70%;
    margin-left: 10%;
    font-size: 22px;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    /*font-family: 'GilroyLight', sans-serif;*/
    margin-top: 5%;
}

.align-baseline{
   align-items: flex-start !important;
}

.join-footer{
    text-align: center;
    margin-top: 5%;
    font-size: 3.2em;
    width: 40%;
    margin-left: 30%;
}

.btn-footer{
    border: 3px solid white;
    width: 15%;
    background: black;
    color: white;
    text-decoration: none;
    font-size: 1.3em;
    position: relative;
    overflow: hidden;
    padding-bottom: 0.8em;
    border-radius: 0.75em;
    margin-top: 2%;
}

.menu-burger{
    display: none;
}

.btn-footer:hover{
    background: white;
    color: black;
}

a{
    color: white;
    text-decoration: none;
}

.emoji-item-footer{
    font-size: 1.5em;
}

.black-logo{
    width: 12em;
}

.block-footer{
    margin-left: 10%;
    color: white;
    font-size: 15px;
    margin-top: 1%;
}

.title-community {
    text-align: left;
    margin-top: 0;
    font-size: 2em;
    color: #271350;
    padding-left: 0;
    margin-left: 0;
}

.btn-footer{
    margin-top: 0;
}

.join-footer{
    text-align: center;
    font-size: clamp(1rem,2vw,3rem);
    width: 30%;
    margin: 0 auto;
    margin-top: 2%;
}

.pitchersales{
    font-weight: 100;
    font-size: 14px;
}

#step{
    border-radius: 40px;
}

#navLinks {
    background-color: white;
    position: absolute;
    top: -100vh;
    left: 0;
    right: 0;
    height: 35vh;
    border-bottom: 5px solid black;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.5s ease-in-out;
}

#navLinks.open {
    top: 0;
    opacity: 1;
    visibility: visible;
}

.content-headband {
    width: 100%;
    text-align: center;
    font-size: clamp(1rem, 1vw, 1.5rem);
    background: #FFED94;
    padding-bottom: 10px;
    color: black;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
}

.cardmedia{
    width: 18em !important;
    height: 17em;
}

.btn-section{
    width: 50%;
    margin-left: 25%;
    margin-top: 5%;
    height: 45px;
    border-radius: 30px;
    background: white;
}

.volt{
    width: 10%;
}

.text-banner{
    width: 55%;
    letter-spacing: 2px;
    line-height: 26px;
    color: white;
    margin: 0 auto;
    padding-top: 1%;
    padding-bottom: 2%;
}

.content-img-banner{
    width: 80%;
    margin-left: 10%;
}

.content-headband-c {
    width: 100%;
    margin-top: 8%;
    text-align: center;
    font-size: 30px;
    background: #271350;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    font-family: 'Montserrat', sans-serif;
    /*font-family: 'GilroyLight', sans-serif !important;*/
    font-weight: 300;
}>

.cookie-image{
    width: 55px;
 }
 .text {
     font-size: 35px;
     font-family: 'Rowdies', cursive;
     color: #FFCD3B;
     align-self: center;
 }

@media screen and (min-width: 768px) {
    .d-flex-desktop {
        display: flex !important;
    }

    .tablette-only{
        display:  none;
    }

    .success {
        border-radius: 1.5em;
        border: black 4px solid;
        height: 30em;
        object-fit: cover;
        width: 32em;
    }



    @keyframes scrollText {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(-100%);
        }
    }
}

.desktop-only {
    display: block;
}

.mobile-only {
    display: none;
}

.style-menu{
    align-items: baseline;
    justify-content: space-between;
    width: 85%;
}

.content-menu-desktop{
    justify-content: space-between;
    max-width: 40%;
    margin-top: 3%;
    align-items: center;
}

.menu-li{
    background: none;
    color: white;
    font-weight: 500;
    font-family: 'Glacial Indifference';
}

.btn-discover:hover{
    color: #271350;
    background: none;
}

.btn-darkblue:hover{
     color: #271350 !important;
     background: #dcb7fb;
}

.btn-darkblue-text:hover{
    color: #271350!important;
}

.get-money-btn{
    background: white;
    padding: 1em;
    border-radius: 4em;
}


@media only screen and (min-width: 769px) and (max-width: 1023px){
    .d-flex{
        display: flex !important;
    }

    .mobile-only {
        display: none;
    }

    .desktop-only {
        display: block;
    }
}

.section-subscribe{
    background: linear-gradient(white 0%, rgba(255, 205, 59, 0.6) 95%);
}

.content-header-Investors{
    /*background: linear-gradient(rgba(230, 204, 255, 0.6) -21%, white 95%);*/
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 5%, rgba(252,233,255,1) 24%, rgba(240,197,242,1) 51%, rgba(212,179,254,1) 91%);
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 50em;
    min-height: 80vh;
}

.fit-none{
    transform: scale(1.4) !important;
    z-index: -1;
}

.content-img-header-investors{
    width: 80%;
}

.content-img-header{
    max-width: 100%;
}

.big-title-investors{
    font-size: 6.5em;
    font-weight: 800;
    margin-bottom: 3%;
    color: white;
    letter-spacing: -3px;
    text-align: center;
    margin-top: 10%;
    font-family: "Abril Fatface", serif !important;
}

.screen{
    max-width: 110%;
}

.buttons-investors{
    margin-top: 5% !important;
    justify-content: space-between;
    margin: 0 auto;
    width: 55%;
}

.block-i{
    width: 45%;
    height: auto;
    margin: 0 auto;
    margin-top: 2%;
    border-radius: 1.875rem;
    padding-bottom: 5%;
}

.content-block-i{
    width: 70%;
    margin: 0 auto;
}

.title-H{
    text-align: center;
    font-size: 3em;
    margin-top: 10%;
}

.content-expert{
    width: 35%;
}

.title-expert{
    text-align: left;
    margin-left: 0;
    width: auto;
    font-size: 2em;
    margin-top: 0;
}

.title-card {
    font-size: 16px !important;
    font-weight: bold !important;
}

.card{
    width: calc(110% - 22%) !important;
    margin-right: 50% !important;
}

.content-expert-global{
    align-items: center;
    margin: 0 auto;
    padding-left: 10%;
    justify-content: space-between;
    background: #271350;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-top: 5%;
}

.block-i-1{
    background: linear-gradient(#DDAEFF -21%,#D7C0FF 95%);
}

.block-i-2{
    background: linear-gradient(white -21%, #DFDFDF 95%);
    /*background: linear-gradient(#FFD37A -21%,#FFFBDF 95%);*/
}

.block-i-3{
    background: linear-gradient(#FFC5E6 -21%,#F9E0FF 95%);
}

.block-i-4{
    background: linear-gradient(#688AFF -21%, #C4D1FF 95%);
}

.block-i-5{
    background: linear-gradient(#FFD37A -21%,#FFFBDF 95%);
}

.block-i-6{
    background: linear-gradient(#FFD37A -21%,#FFFBDF 95%);
}

.content-block-H{
    margin: 0 auto;
    justify-content: space-between;
    width: 70%;
    margin-top: 5%;
}

.small-title-w{
    width: 80%;
    margin-left: 10%;
    font-family: 'Montserrat', sans-serif;
    /*font-family: 'GilroyLight', sans-serif;*/
    font-weight: 700 !important;
    margin-top: 0;
    font-size: clamp(0rem,1.1vw,1.2rem);
    line-height: 27px;
    text-align: center;
    color: #271350;
}

.pic-todo{
    width: 40%;
    margin-top: 10%;
    text-align: center;
    margin-left: 30%;
}

.txt-w{
    font-size: clamp(1.3rem,7vw,1rem);
    padding-top: 10%;
    margin-top: 0;
    width: 76%;
    margin-left: 12%;
    margin-bottom: 5%;
    text-align: center;
    color: #271350;
    font-weight: bold;
}

.block-H{
    width: 25em;
    height: 40em;
    border-radius: 1.875rem;
}

.violet-volt{
    width: 4%;
    margin-right: 4%;
}

.li-type{
    list-style: none;
    align-items: center;
    display: flex;
}

.block-H-1{
    background: #FFF9E5;
}

.block-H-2{
    background: linear-gradient(#FFD37A -21%,#FFFBDF 95%);
}

.block-H-3{
    background: #FFDF73;
}

.text-i-1{
    color: white;
    font-size: clamp(1rem,2vw,3rem);
    padding-top: 15%;
    padding-left: 5%;
    margin-top: 0;
    width: 85%;
    font-weight: 800;
}

.img-i{
    width: 45%;
    margin-top: 5%;
}

.img-i-t{
    width: 25%;
}

.img-i-p{
    width: 30%;
    margin-top: 5%;
}

.img-i-b{
    width: 30%;
}

.img-i-j{
    width: 25%;
    margin-top: 10%;
}

.img-i-4{
    width: 20%;
    margin-top: 5%;
}

.r1{
    width: 325px;
    margin-top: 10%;
}

.r3{
    width: 845px;
    margin-left: -25%;
    margin-top: -20%;
}

.selected-img {
    background: #f1f4f8;
    transition: 0.3s;
    height: 115px;
    width: 35%;
}

.title-parcours{
    font-size: 2em;
    margin-top: 0;
}

.card-s:hover{
    background: #f1f4f8;
    transition: 0.3s;
    height: 115px;
    width: 35%;
}

.content-slogan{
    background: white;
    margin-top: 5%;
    color: white;
    font-size: 1.2em;
    text-align: center;
    transform-origin: 40% 100%;
    background: #271350;
    width: 76%;
    margin: 0 auto;
    border-radius: 20px;
    padding: 5% 0 7% 0;
    justify-content: space-between;
    padding-left: 2%;
    padding-right: 2%;
    position: relative;
}

.txt-slogan{
    text-align: center;
    margin: 0 auto;
    margin-top: 2%;
    font-family: 'Glacial Indifference';
    font-weight: 100;
    font-size: 22px;
    color: white;
    margin-top: 2em;
}

.content-constat{
    width: 90%;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 5%;
}

.img-constat{
    height: 45em;
    object-fit: cover;
    border-radius: 20px;
}

.txt-constat{
    width: 33%;
    font-family: 'Glacial Indifference', sans-serif;
}

.text-constat{
    width: 100%;
    letter-spacing: 2px;
    line-height: 26px;
}

.title-constat{
    text-align: center;
    margin-top: 0;
    font-size: 2em;
    color: #271350;
    padding-left: 0;
    margin-left: 0;
    padding-top: 5%;
    margin-bottom: 5%;
}

.w-footer{
    width: 20%;
}

.img-content{
    width: 3.5em;
    margin-top: 10%;
    align-self: end;
}

.last-text-constat{
    letter-spacing: 2px;
    font-size: 2em;
    color: #271350;
    margin-top: 10%;

}

.content-step{
    margin: 0 auto;
    justify-content: space-between;
    margin-top: 10%;
    padding-right: 10%;
    width: 90%;
}

.img-step{
    height: 28em;
    object-fit: cover;
    width: 40%;
    margin-left: 10%;
    border-radius: 20px;
}

.txt-step{
    margin-left: 10%;
    align-self: center;
}

.title-crit{
    font-size: 2em;
}

.text-step{
    width: 95%;
    line-height: 30px;
    color: white;
    font-family: 'Glacial Indifference';
    font-size: 22px;
    font-weight: 100;
}

.input-contact:focus{
    border: 4px solid #f1f4f8;
    outline: none;
    transition: 0.3s;
}

.font-bold{
    font-weight: bold;
}

.light-bold{
    font-weight: 500;
}

.btn-offers{
    background: #FFCD3B;
    color: #271350;
    letter-spacing: 0px;
    width: 320px !important;
    height: 3em;
    border-radius: 4em;
    font-size: 22px;
    border: none;
    margin-top: 5%;
}

.mini-title-notice{
    color: #FFCD3B;
    letter-spacing: 3px;
}

/************************RESPONSIVE MOBILE******************************/

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes elastic {
    0% {
        transform: translateX(100%);
    }
    60% {
        transform: translateX(-5%);
    }
    80% {
        transform: translateX(3%);
    }
    100% {
        transform: translateX(0%);
    }
}

.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
    overflow-y: auto;
    transform: translateY(-100%);
    transition: transform 0.3s;
    text-align: center;
    box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    align-content: center;
    padding-bottom: 10%;
    padding-top: 10% !important;
}

.mobile-menu.show-menu {
    transform: translateY(0);
    animation: slideDown 0.8s;
    flex-direction: column;
    display: flex
;
}

.mobile-menu-button {
    margin-top: 7%;
    background: none;
    color: #271350;
    font-size: clamp(1.1rem,1.1vw,1.7rem)
}

.home-btn-menu-mobile{
    background: #FFCD3B;
    padding: 15px;
    border-radius: 40px;
    width: 40%;
}

.investors-btn-menu-mobile{
    background: #9A41FF;
    padding: 15px;
    border-radius: 40px;
    color: white;
    width: 40%;
}

.consultant-btn-menu-mobile{
    background: #FF7569;
    padding: 15px;
    border-radius: 40px;
    color: white;
    width: 40%;
}

.lif:hover{
    color: #d7c0ff;
}

.content-block{
    justify-content: space-between;
    margin-top: 5%;
}

.block1{
    height: 25em;
    width: 30%;
    background: #5e5dcd;
}

.block2{
    height: 25em;
    width: 30%;
    background: #d7c0ff;
}

.block3{
    height: 25em;
    width: 30%;
    background: #ffedb8;
}

.img-section{
    width: 20%;
    padding-top: 10%;
    margin-left: 40%;
}

.subtitle-section{
    padding-left: 10%;
    padding-right: 10%;
}

/********************** RESPONSIVE **********************/
@media (max-width: 380px) {

    .content-headband{
        padding: 1%;
    }

    .selected-button-i {
        margin-left: 0 !important;
    }

    .icon-btn-menu-i {
        margin-left: 0%;
    }

    .img-i-t {
        width: 20%;
        margin-top: -6%;
    }

    .img-i-4 {
        width: 18%;
        margin-top: 0;
    }

    .icon-btn-menu-i{
        display: none;
    }

    .selected-button-i{
        text-align: center;
        margin: 0;
        padding-left: 8%;
    }
}

.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 2%;
    right: 0;
    background-color: white;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px -2px 4px rgb(0 0 0 / 10%);
    z-index: 9999;
    width: 25%;
    padding: 2em;
    text-align: center;
}

.cookie-image {
    width: 3em;
    height: auto;
    object-fit: contain;
    margin: 0 auto;
}

.cookie-banner p {
    margin: 0;
    font-size: clamp(0rem,1vw,1.2rem);
    line-height: 1.5;
    color: #333;
    margin-top: 5%;
    margin-bottom: 5%;
}

.img-banner{
    width: 30%;
    object-fit: contain;
}

.feu{
    width: 2%;
    object-fit: contain;
    margin-left: 1%;
}

.btn-banner{
    font-size: clamp(0rem, 1.1vw, 1.2rem);
    background: #E73346;
    color: white;
    letter-spacing: 0px;
    align-items: center;
    width: 20%;
    font-weight: 500;
    justify-content: center;
    border-radius: 4em;
    height: 3.5em;
    margin: 0 auto;
    margin-top: 3%;
}

.btn-banner-join{
    font-size: clamp(0rem, 1.1vw, 1.2rem);
    background: #271350;
    color: white;
    letter-spacing: 0px;
    align-items: center;
    width: 40%;
    font-weight: 500;
    justify-content: center;
    border-radius: 4em;
    height: 3.5em;
    margin: 0 auto;
    margin-top: 3%;
    font-size: clamp(0rem, 1.1vw, 1.2rem);
    color: white;

}

.btn-banner-join-expert{
    color: #271350;
    letter-spacing: 0px;
    align-items: center;
    width: 60%;
    font-weight: 500;
    justify-content: center;
    border-radius: 4em;
    height: 3.5em;
    margin-top: 7%;
    margin-left: 0;
    background: #dcb7fb;
    font-family: 'Glacial Indifference';
}

.btn-banner-join:hover{
background: #FFED94;
color: #271350;
}

.btn-banner-join-expert:hover{
    background: #271350;
    color: #dcb7fb;
    border: 2px solid #dcb7fb;
}



.content-wework{
height: 30em;
text-align: center;
margin: 0 auto;
}

.wework-img{
height: 18em;
margin: 0 auto;
border-radius: 35px;
object-fit: contain;
}

.btn-banner-join-contact{
font-size: clamp(0rem, 1.1vw, 1.2rem);
border: 2px solid #271350;
color: #271350;
letter-spacing: 0px;
align-items: center;
width: 35%;
font-weight: 500;
justify-content: center;
border-radius: 4em;
height: 3.5em;
margin: 0 auto;
margin-top: 3%;
}

.content-btn-join{
justify-content: space-around;
width: 50%;
margin-left: 25%;
}

.content-numbers{
justify-content: space-around;
text-align: left;
margin-left: 10%;
align-items: flex-start;
}

.mini-title-content-icons{
font-size: 0.8em;
margin-top: 15%;
}

.content-icons{
justify-content: space-between;
text-align: left;
width: 90%;
margin-left: 10%;
margin-top: 5% !important;
}

.content-icons-footer{
justify-content: space-between;
text-align: left;
width: 6%;
font-size: 22px;
margin-left: 10%;
}

.content-items{
width: 25%;
display: grid;
align-items: flex-start;
}

.content-love{
margin-top: 8%;
margin-bottom: 10%;
}

.first-love{
width: 50%;
margin-left: 25%;
}

.second-love{
width: 80%;
margin-left: 10%;
}

.btn-love{
font-size: clamp(0rem, 1.1vw, 1.2rem);
color: white;
letter-spacing: 0px;
align-items: center;
width: auto;
font-weight: 500;
justify-content: center;
border-radius: 4em;
height: 3.5em;
margin: 0 auto;
margin-top: 3%;
padding-left: 3%;
padding-right: 3%;
align-content: center;
color: #271350;
align-items: center;
display: grid;
}

.btn-1-l{
border: 2px solid #EBA1FE;
}

.btn-2-l{
border: 2px solid #4BA4F7;
}

.btn-4-l{
border: 2px solid #FF8340;
}

.btn-5-l{
border: 2px solid #900C3F;
}

.btn-3-l{
border: 2px solid #3AD418;
}


.btn-com-2{
    background: #271350;
    color: white;
    align-items: center;
    width: 50%;
    justify-content: center;
    border-radius: 4em;
    height: 3.5em;
    margin-left: 0;
    margin-top: 8%;
    font-family: 'Glacial Indifference';
    font-size: 22px;
}

.btn-com-2:hover{
    background: #dcb7fb;
    color: #271350;
}

.btn-com-f{
background: #271350;
letter-spacing: 0px;
align-items: center;
width: 40%;
font-weight: 500;
justify-content: center;
border-radius: 4em;
margin: 0 auto;
color:  white;
margin-top: 5%;
font-size: clamp(0rem, 1.1vw, 1.2rem);
}

.number-line{
font-size: 5em;
position: absolute;
margin-top: -30%;
background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(218, 200, 255, 0.61) 15%, rgba(210, 170, 247, 1) 35%, rgba(236, 191, 250, 1) 65%, rgba(251, 255, 113, 1) 100%, rgba(250, 255, 75, 1) 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
color: transparent;
font-family: "Abril Fatface", serif;
}


.title-content{
text-align: center;
font-size: 2em;
width: 40%;
margin-left: 30%;
color: white;
padding-top: 4%;
}

.title-love{
text-align: center;
font-size: 2em;
width: 40%;
margin-left: 30%;
color: #271350;
}

.mini-title-content{
font-size: 4em;
color: #271350;
margin-bottom: 0;
}

.mini-title-content-2{
font-size: 1.3em;
color: #271350;
}

.text-icons{
font-size: 0.6em;
width: 80%;
text-align: justify;
}

.text-banner-content{
letter-spacing: 2px;
line-height: 22px;
width: 80%;
}

.content-feu{
margin-top: 5%;
justify-content: center;
transform: rotate(-3deg);
font-family: "Yellowtail", cursive !important;
font-weight: 400;
font-size: 25px;
}

/*MOBILE*/
@media only screen and (max-width: 768px) {
    .tablette-only{
        display: none;
    }

    .content-header-event{
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 5%, rgba(252, 233, 255, 1) 24%, rgba(240, 197, 242, 1) 51%, rgba(212, 179, 254, 1) 91%);
        align-items: center;
        flex-direction: column;
        width: 100%;
        min-height: 25vh;

    }

    .timeline-text.left {
        left: calc(0% - 135px) !important;
        text-align: center !important;
        font-size: 18px;
    }

    .text-financement{
        font-size: 18px;
    }

    .seven{
        font-size: 55px;
    }

    .txt-slogan{
        margin-bottom: 15%;
        font-size: 18px;
        width: 100%;
        text-align: center;
    }

    .timeline-text.right {
        left: calc(-750% + 90px) !important;
        text-align: center !important;
        font-size: 18px;
    }

    .number-line{
        margin-left: 25%;
        font-size: 4em;
        margin-top: -35%;
    }

    .timeline-text {
        position: absolute;
        width: 8em !important;
        font-size: 20px;
        font-family: 'Glacial Indifference';
    }

    .btn-banner-join-expert{
        border-radius: 8em;
        display: flex;
        font-size: 22px;
        height: 3.5em;
        margin-top: 10% !important;
        margin: 0 auto;
        width: 100%;
    }

    .card{
        width: 90% !important;
        /*margin-right: 50% !important;*/
    }

    .slick-list {
        width: 100%;
    }

    .slick-slide img {
        display: block;
        object-fit: cover;

    }

    .title-notice{
        font-size: clamp(1.5rem,2vw,1.2rem);
        margin-left: 10%;
        margin-top: 10%;
    }

    .p-legal{
        font-size: clamp(1.1rem,1.1vw,1.7rem);
    }

    .mini-title-notice {
        color: #FFCD3B;
        letter-spacing: 3px;
        font-size: clamp(1rem,2vw,2rem);
        margin-left: 0;
    }

    .content-legal-notice {
        width: 80%;
        margin-left: 10%;
    }

        .cookie-banner{
        width: 100%;
        padding: 0em;
        left: 0;
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .refuse-cookie, .accept-cookie{
        font-size: clamp(1rem,1vw,1.3rem);
    }

    .cookie-banner p {
        margin: 0;
        font-size: clamp(1rem,1.1vw,1.3rem);
        line-height: 1.5;
        color: #333;
        margin-top: 5%;
        padding-left: 3%;
        padding-right: 3%;
    }

    .cookie-image {
        width: 3em;
    }

    .logo-s{
        display: block !important;
        margin: 0 auto;
        padding: 6%;
        width: 3.8em;
    }

    .hr-footer {
        width: 55%;
        margin-left: 15%;
    }

    .mini-title-solution{
        margin-left: 0;
    }

    .input-container input{
        flex-basis: auto;
        width: 100%;
        height: auto;
        font-size: clamp(1rem,1.1vw,1rem);
        margin-left: 0;
        padding-left: 5%;

    }

    .input-container button {
        flex-basis: 15%;
    }


    .content-input-welcome{
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-top: 5%;
    }

    .content-header-home{
        height: 50em;
    }

    .content-header-Investors{
      height: auto;
    }

    .content-header-Consultant{
        height: 45em;
    }


    .small-txt-offers{
        font-size: clamp(1.1rem,1.1vw,1.7rem);
    }

    .d-flex-mobile{
        display: flex;
    }

    .button-container-investors{
        width: 90%;
    }

    .section-HEADER {
        position: relative;
        z-index: 1;
    }

    .section-Headband {
        position: relative;
        z-index: 0;
    }

    .txt-w {
        font-size: clamp(1.3rem,1.1vw,3rem);
    }


    /***************HEADER**************/
    .content-header{
        align-items: center;
        width: 100%;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
        padding-bottom: 0;
        display: grid;
        grid-template-rows: auto auto;
        row-gap: 20px;
        margin-top: -30%;
    }

    .big-title{
        font-size: 38px;
        font-weight: 700;
        line-height: 47px;
        margin-bottom: 3%;
        text-align: center;
        margin-left: 0;
    }

    .screen{
        width: 380px;
        margin: 0 auto;
    }

    .content-img-header{
        grid-row: 1;
        width: inherit;
        text-align: center;
        margin-top: 10%;
    }

    .content-container-investors{
        grid-row: 2;
    }

    .icon-btn-menu{
        width: 70px;
        margin-left: 3%;
        margin-right: 5%;
    }

    .pic-welcome{
        width: 100%;
        margin-top: 10%;

    }

    .subtitle-header{
        font-size: 18px;
        margin-top: 10%;
        color: #271350;
        line-height: 30px;
        width: 100%;
        margin-left: 0;
    }

.selected-button{
    width: 100% !important;
    font-size: 22px;
    letter-spacing: 0px;
    align-items: center;
    display: flex;
    height: 3em;
    font-family: 'Glacial Indifference';
    font-weight: bold;
    margin-top: 10%;
    justify-content: center;
    font-size: 18px;
}

.mix-btn{
    font-size: 18px;
}

.logo{
margin-top: 2% !important;
}

.title-carousel{
text-align: center;
margin-top: 20% !important;
font-size: 1.5em;
width: 40%;
color: #271350;
width: 90%;
margin: 0 auto;
}

.img-carrousel{
width: 20%;
margin-left: 5%;
}

.content-carousel{
text-align: center;
margin-top: 10%;
}

.title-slogan{
margin: 0 auto;
font-size: 1.1em;
padding: 5%;
}

.title-constat{
font-size: 1.8em;
margin-top: 10%;
text-align: left !important;
}

.title-parcours{
font-size: 1.8em;
margin-top: 10%;
text-align: left !important;
margin-left: -10% !important;
}

.title-love{
font-size: 1.8em;
width: auto;
margin-left: 0;
}

.first-love{
width: auto;
margin: 0 auto;
text-align: center;
padding-left: 10%;
padding-right: 10%;
}

.btn-love{
font-size: 1em;
margin-top: 10%;
width: auto;
text-align: center;

}

.content-join {
height: 32em;
background: #9A41FF;
width: 100%;
margin-top: 15%;
}

.text-banner{
font-size: 1em;
width: 84%;
margin-left: 8%;
}

.content-btn-join {
justify-content: space-around;
width: 80%;
margin-left: 10%;
margin-top: 10%;
}

.btn-banner-join{
display: flex;
width: 100%;
height: 4em;
font-size: 1em;
margin: 0 auto;
}

.content-icons-footer{
display: flex;
margin-top: 5%;
margin-bottom: 5%;
width: 25%;
    margin-left: 15%;
}

.title-content {
text-align: center;
font-size: 2em;
width: 80%;
margin-left: 10% !important;
color: white;
padding-top: 10%;
font-size: 1.5em;
}

.text-constat{
font-size: clamp(1.1rem,1.1vw,1.7rem);
padding-left: 0;
}

.last-text-constat {
font-size: clamp(1.1rem,1.3vw,1rem);
margin-left: -10%;
}

.content-slogan{
    margin-top: 15%;
}

.img-constat{
width: 100% !important;
display: grid;
margin: 0 auto;
height: 20em !important;
}

/*.slick-track {*/
    /*    width: 100% !important;*/
    /*    display: flex;*/
    /*}*/

    .btn-com-2{
        height: 3.5em;
        border-radius: 8em;
        display: flex;
        font-size: 18px;
        margin-top: 10% !important;
        width: 100%;
        margin: 0 auto;
    }

    .title-expert{
        font-size: 1.8em;
        margin-top: 0%;
        text-align: center;
    }

    .text-step{
        width: 100%;
        text-align: center;
        font-size: 18px;
    }

    .img-step{
        width: 90%;
        display: block;
        margin-left: 10%;
        height: 15em;
        margin-top: 10%;
        text-align: center;
        margin-left: 10%;
        order: 2;
    }

    .content-step {
        flex-wrap: wrap;
        display: flex;
    }



    .title-community{
        font-size: 1.8em;
        margin-top: 10%;
        text-align: center;
    }

    .content-items{
        width: auto;
    }

    .text-banner-content{
        text-align: center;
        width: 90%;
        margin-left: 0%;
    }

    .mini-title-content, .mini-title-content-2{
        text-align: center;
        margin-left: -10%;
    }

;



    .img-constat {
        height: 20em;
        object-fit: cover;
        margin: 0 auto;
        text-align: center;
        justify-content: center;
        display: block;
        width: 90%;
    }

    .selected-button-i{
        width: 100% !important;
        color: white;
        font-size: clamp(1.1rem,1.1vw,1.7rem);
        letter-spacing: 0px;
        align-items: center;
        display: flex;
        height: 3em;
        font-weight: bold;
        margin-top: 5%;
        justify-content: center;
    }

    .selected-button-c{
        width: 80% !important;
        color: white;
        font-size: clamp(1.1rem,1.1vw,1.7rem);
        letter-spacing: 0px;
        align-items: center;
        display: flex;
        height: 3em;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        margin-top: 5%;
        justify-content: center;
    }

    .icon-btn-menu-c{
        display: none;
    }

    /***************BANNER**************/

    .content-headband{
        font-size: clamp(1.1rem,1.1vw,1.2rem);
        width: auto;
        padding: 3% !important;
        margin-top: 0%;

    }

    .content-headband-c{
        margin-top: 0%;
        font-size: 18px;
        width: auto;
    }

    .btn-cta-i{
        width: 60% !important;
        font-size: clamp(1.1rem,1.1vw,1.7rem);
        font-weight: 600;
    }

    /***************OFFERS**************/

    .title-offers-price{
        font-size: clamp(1.7rem,1.1vw,3rem);
        text-align: left;
        width: 85%;
        margin-left: 10%;
        color: #271350;
        margin-top: 10%;
    }

    .btn-offers{
        margin-top: 7%;
        font-size: clamp(1.1rem,1.1vw,1.7rem);
        font-weight: 600;
    }

    .content-adv-c{
        margin-top: 0;
    }

    .btn-cta-c{
        width: 60% !important;
        height: 3em;
        font-size: clamp(1.1rem,1.1vw,1.7rem);
        font-weight: 600;
    }

    .text-concept{
        margin-left: 10%;
        text-align: left;
        line-height: 30px;
        width: 80%;
        font-size: 23px;
    }

    .block-text-meeting{
        flex-direction: column;
        width: auto;
        margin-left: 0;
    }

    .w-30{
        width: initial;
    }

    .content-s-right{
        margin-left: 0;
    }

    .small-title-w{
        width: 80%;
        margin-left: 10%;
        font-family: 'Montserrat', sans-serif;
        /*font-family: 'GilroyLight', sans-serif;*/
        font-weight: 700 !important;
        margin-top: 0;
        font-size: clamp(1.1rem,1.1vw,1.7rem);
        line-height: 27px;
        text-align: center;
        letter-spacing: 1px;
        color: #271350;
    }

    .img-i-t{
        width: 25%;
    }

    .pic-concept{
        width: 150px;
        margin-top: 10%;
    }

    .btn-cta-h{
        width: 60% !important;
        font-size: clamp(1.1rem,1.1vw,1.7rem);
        font-weight: 600;
    }

    .icon-btn-menu-i{
        margin-right: 0;
        display: none;
    }

    .icon-btn-menu{
        margin-right: 0;
        display: none;
    }

    .content-solutions-c{
        width: 88%;
        margin-left: 6%;
    }

    /***************CONCEPT**************/

    .pic_solution{
        width: 100%;
        border-radius: 40px;
        height: 17em;
        grid-row: 1;
    }

    .big-title-solution{
        margin-left: 0;
        font-size: clamp(1.7rem,1.1vw,3rem);
        width: 100%;
    }

    .text-solution-right{
        margin-left: 0;
        width: 100%;
        font-size: clamp(1.1rem,1.1vw,1.7rem);
        color: #271350;
        margin-bottom: 20%;
    }

    .text-solution-left{
        width: 100%;
        color: #271350;
        font-size: clamp(1.1rem,1.1vw,1.7rem);
    }

    .content-solution-mobile{
        display: grid;
    }


    /***************ADVANTAGES**************/

    .block-i{
        width: 95%;
        margin-bottom: 5%;
    }

    .text-i-1{
        font-size: clamp(1.7rem,1.1vw,3rem);
        padding-left: 10%;
    }

    .img-i{
        width: 45%;
    }

    .hands {
        width: 55%;
    }

    .content-block-i{
        width: 90%;
        margin: 0 auto;
    }

    .content-advantage{
        padding-top: 10%;
        padding-bottom: 10%;
        height: auto;
        display: grid;
        width: 100%;
        margin-top: 0;
    }

    .content-solutions{
        width: 100%;
        margin-left: 0%;
    }

    /***************COMMUNITY**************/

    .content-community{
        width: 90%;
        margin-left: 5%;
    }

    .text-com{
        width: 80%;
        margin-left: 10%;
    }

    .title-com{
        font-size: clamp(1.7rem,1.1vw,3rem);
        text-align: left;
        width: 90%;
        margin-left: 0%;
        margin-top: 10%;
        color: #271350;
        font-weight: 800;
        letter-spacing: inherit;
    }

    .pic-header{
        display: none;
    }

    .content-img-header-investors {
        margin-top: 0%;
    }

    .btn-com{
        width: 100% !important;
        margin-bottom: 10%;
        font-size: clamp(1.1rem,1.1vw,1.7rem);
        font-weight: 600;
    }

    .title-step{
        font-size: 30px;
        width: 90%;
        margin-left: 10%;
        margin-top: 10%;
    }

    .txt-solutions {
        margin-left: 10%;
        text-align: left;
        font-size: clamp(1.1rem,1.1vw,1.7rem);
        font-family: 'Montserrat', sans-serif;
        /*font-family: 'GilroyLight', sans-serif;*/
        width: 80%;
        color: #271350;
    }

    .txt-com{
        font-size: clamp(1.1rem,1.1vw,1.7rem);
    }

    .gift{
        display: none;
    }

    .content-block-offers{
        flex-direction: column;
        margin-left: 0;
        width: auto;
    }

    .block-tarif{
        width: 88%;
        border-radius: 30px;
        border: 4px solid #f1f4f8;
        box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
        margin-left: 5%;
        margin-top: 10%;
        padding-top: 5%;
        padding-bottom: 10%;
        height: auto;
    }

    .title-step-c{
        text-align: left;
        letter-spacing: 3px;
        font-size: clamp(1.7rem,1.1vw,3rem);
        color: #271350;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 10%;
        padding-top: 10%;
    }

    .txt-solutions-c {
        text-align: left;
        font-size: clamp(1.1rem,1.1vw,1.7rem);
    }

    .btn-cta-end{
        background: #FFCD3B;
        color: black;
        letter-spacing: 0px;
        width: 70% !important;
        height: 3em;
        border-radius: 4em;
        font-size: clamp(1.1rem,1.1vw,1.7rem);
        border: none;
        margin-left: 15%;
        margin-top: 1%;
        margin-bottom: 10%;
        font-weight: 600;
    }

    .btn-cta-end-c{
        letter-spacing: 0px;
        width: 60% !important;
        height: 3em;
        border-radius: 4em;
        font-size: clamp(1.1rem,1.1vw,1.7rem);
        border: none;
        margin-top: 1%;
        margin-bottom: 10%;
        margin-left: 0;
        font-weight: 600;
    }

    .title-offers{
        font-size: clamp(1.3rem,1.1vw,3rem);
        margin-top: 5%;
    }


    .small-title-offers{
        line-height: 15px;
        width: 85%;
    }

    /***************COMMUNITY**************/

    .content-card-s{
        width: 120%;
        margin-left: -10%;
    }

    .pic-s{
        width: 75%;
        padding-top: 10%;
        margin-left: 15%;
    }

    .input-contact{
        margin-top: 7%;
        width: 110%;
        margin-left: -10%;
        padding-left: 10%;
    }

    .btn-form{
        width: 70% !important;
        margin-top: 8%;
        font-size: 20px;
        margin-left: 15% !important;
    }

    .logo{
        width: 9em;
        margin-top: 8%;
        margin-left: 5%;
    }

    .input-container{
        padding: 0.3rem;
        text-align: center;
        max-width: 90%;
        margin-left: 5%;
    }

    .content-icons{
        width: auto;
        margin-top: 5% !important;
        margin-bottom: 5%;
    }

    .icons {
        font-size: clamp(1.3rem, 4vw, 2rem);
        margin-left: 3%;
        margin-right: 3%;
    }

    .content-text-welcome {
        max-width: 98%;
        align-self: end;
        margin-top: 5%;
        text-align: center;
        margin: 0 auto;
    }

    .txt-welcome {
        width: 80%;
        font-size: clamp(1rem, 4vw, 1.3rem);
        margin: 0 auto;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    #cookiePopup{
        width: 88%;
        padding: 4% 2% 4% 2%;
        margin-left: 4%;
        bottom: 0%;
    }

    .title-parcours{
        text-align: center !important;
        margin-left: 0 !important;
    }

    .mobile-only{
        display: block;
    }

    .txt-constat{
        width: 90%;
        margin-left: 5%;
    }


    .desktop-only{
        display: none;
    }

    .join-us{
        margin-top: 15%;
    }

    .menu-burger{
        display: block;
        width: 12%;
        object-fit: contain;
        transition: opacity 0.10s ease-in-out;
        position: fixed;
        right: 12%;
        z-index: 1000;
        top: 2%;
        transform: scale(0.8);
    }

    .content-expert{
        width: 80%;
        padding-top: 10%;
        padding-bottom: 10%;
        margin: 0 auto;
    }

    .content-expert-global{
        padding-bottom: 15%;
        padding-left: 0;
    }

    .border-icons-f{
        display: block;
        padding: 0.5em;
        margin: 0 auto;
        object-fit: contain;
        border-radius: 100%;
    }

    .content-icons-f{
        display: flex;
        flex-direction: row;
        margin-top: 30%;
    }

    .timeline-container{
        margin-top: 25% !important;
    }

    .content-menu-desktop{
        display: none;
    }

    .content-icons-f-mobile-2{
        margin-top: -5%;
    }

    .Content-box {
        width: 90%;
        margin: 0 auto;
    }

    .violet-volt {
        width: 9%;
        margin-right: 10%;
    }

    .marge-i-2 {
        margin-top: -25% !important;
    }

    .content-f{
        width: 90%;
    }

    .content-header{
        padding-left: 0;
    }

    .buttons-investors {
        text-align: center;
        margin-left: 0% !important;
        flex-direction: column;
    }

    .btn-com-f{
        width: 90% !important;
        display: flex;
        height: 3.5em;
        border-radius: 5em;
        font-size: 18px;
        margin-top: 10% !important;
        margin: 0 auto;
    }

    .text-btn-header{
        font-size: 18px;
    }

    .content-carousel{
        width: 75%;
        justify-content: space-between;
        text-align: center;
        padding: 0;
        position: relative;
    }

    .button-container-investors{
        margin: 0 auto;
    }

    .marge-i-5 {
        margin-top: 30% !important;
    }

    .title-financement{
        font-size: 1.8em;
        margin-top: 5%;
    }

    .text-financement{
        text-align: center;
        width: 90%;
    }

    .menu-burger.scrolled {
        background-color: white;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease;
        border-radius: 100%;
        width: auto;
        margin-top: 10%;
    }

    .buttons-menu{
        font-size: 0.6em;
    }

    .pictures{
        margin-top: 0;
    }

    .content-menu-mobile{
        margin-top: 3%;
        justify-content: space-between;
        width: 85%;
        z-index: 2000;
        color: #271350;
        display: flex;
        align-items: center;
        position: absolute;
    }

    .buttons-investors{
        text-align: center;
        margin-left: 10%;
        width: auto;
        color: white;
        font-size: 18px !important;
    }

    .mix-btn{
        width: 80% !important;
        margin: 0 auto;
        margin-top: 10% !important;
        font-weight: normal;
    }

    .contact{
        padding: 0.6em 2em;
        margin-top: 10%;
        font-size: 1.3em !important;
    }

    .big-title{
        text-align: center;
        width: 100%;
        margin-top: 50%;
    }

    .btn-form{
        width: 40% !important;
        font-size: 0.8em;
        margin-left: 30% !important;
    }

    .section-concept{
        padding-bottom: 15%;
    }

    .subtitle{
        width: 90%;
        margin: 0 auto;
        font-size: 1em;
    }

    button.learn-more{
        width: 30%;
        margin: 0 auto;
        font-size: 0.8em;
        margin-left: 35%;
        margin-top: 5%;
        text-align: center;
        padding: 1em;
    }

    .pic-1{
        height: 23em;
    }

    .pic-2{
        width: 10em;
        height: 5em;
        border: black 2px solid;
        border-radius: 1em;
    }

    .title-concept{
        font-size: 25px;
    }

    .text-concept{
        font-size: clamp(1.1rem,1.1vw,1.7rem);
        width: 80%;
        margin: 0 auto;
        font-weight: bold;
        margin-left: 10%;
    }

    .big-title-concept{
        width: 90%;
        font-size: 16px;
    }

    .block-concept{
        width: 100%;
        margin: 0 auto;
        font-size: 1em;
        height: 13em;
        margin-top: 10% !important;
    }

    .title-subscribe{
        font-size: 25px;
        text-align: center;
        width: 100%;
    }

    .emoji-item-form-subscribe{
        display: none;
    }

    .emoji-item{
        font-size: 2em;
    }

    .title-step{
        font-size: clamp(1.7rem,1.1vw,3rem);
        width: 80%;
        margin-left: 10%;
    }

    .subtitle-step{
        width: 70%;
        margin-left: 5%;
        font-size: 22px;
        text-align: left;
        font-family: 'Montserrat', sans-serif;
        /*font-family: 'GilroyLight', sans-serif;*/
            margin-top: 5%;
    }

    .item{
        width: 100%;
        font-size: 0.9em;
    }

    .content-subscribe{
        width: 75%;
        padding: 2em;
    }

    .margin-item-form{
        font-size: 3em;
        margin-left: 40%;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .text-form{
        width: 90%;
        font-size: 1em;
        margin: 0 auto;
    }

    .form{
        margin-top: 10%;
        width: 100%;
    }

    .join-footer{
        width: 90%;
        font-size: 25px;
        margin: 0 auto;
        margin-top: 15%;
    }

    .btn-footer{
        width: 50%;
        margin: 0 auto;
        margin-top: 15%;
        font-size: 17px;
    }

    .block-footer{
        margin-left: 15%;
        font-size: 10px;
        margin-top: 5%;
    }

    .footer{
        height: auto;
    }

    .logo-s{
        width: 3.5em;
        padding: 7%;
    }

    .subtitle-subscribe{
        font-size: clamp(1.1rem,1.1vw,1.7rem);
    }

    .content-footer{
        font-size: clamp(1.1rem,1.1vw,1.7rem);
        padding-top: 5%;
        margin-left: 15%;
    }

    .small-logo{
        display: none;
    }

    .icons-footer{
        margin-right: 3%;
        font-size: 25px;
    }

    .text-footer {
        font-size: clamp(0.8rem,1.1vw,0.3rem);
        width: 60%;
        padding-top: 1%;
        font-weight: 200;
        margin-left: 15%;
    }

    .form-s {
        margin: 0 auto;
        width: 130%;
        margin-left: -15%;
    }

    .subtitle-subscribe{
        width: 90%;
    }
}

/*IPAD MINI*/
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (-webkit-min-device-pixel-ratio: 1) {
    App{
        font-size: clamp(0rem,1.9vw,5rem);
    }

    .mini-title-content, .mini-title-content-2{
        margin-left: 0%;
    }

    .title-constat {
        font-size: 1.5em;
    }

    .content-header-Investors{
        padding-right: 5%;
    }

    .content-menu-desktop {
        max-width: 40%;
    }

    .style-menu{
        width: 80% !important;
    }

    .title-financement{
        font-size: 1.5em;
    }

    .title-content{
        text-align: center;
    }

    .style-menu {
        margin-left: 5%;
        width: 80%;
    }

    .content-join {
        height: auto !important;
    }

    .btn-banner-join{
        margin-bottom: 15%;
    }

    .content-f{
        margin-top: 0;
    }

    .img-step {
        width: 90%;
        display: block;
        margin-left: 10%;
        height: 20em;
        margin-top: 0%;
        text-align: center;
        margin-left: 10%;
    }

.content-constat{
flex-direction: column;
}

.title-parcours {
margin-top: 0;
}

.big-title-investors{
padding-top: 30%;
}

.content-carousel {
    text-align: center;
    height: 5em;
    padding-left: 0;
    justify-content: space-between;
    width: 75%;
    margin-top: 10%;
    margin-left: 0;
}

.selected-button{
width: 70% !important;
margin-bottom: 0%;
font-size: clamp(0rem,2.5vw,4rem);
display: block;
margin-left: 15%;
}

.button-container-investors {
width: 100%;
}

.content-icons-footer {
    display: flex;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 15%;
    margin-left: 12%;
}

.btn-banner-join{
width: 55%;
}

.w-footer {
width: auto;
}

.title-community{
margin-top: 0;
}

.content-expert-global{
flex-direction: column;
}

.btn-com-f{
    width: 45% !important;;
}

.btn-banner-join-expert{
    width: 60%;
}

.content-step{
    margin: 0 auto;
    width: auto;
    margin-top: 10%;
}
.btn-com-2{
width: 50%;
}

.content-icons-f {
display: flex;
flex-direction: row;
margin-top: 10%;
width: 80%;
}

.content-icons-f-mobile-2 {
margin-top: -5%;
margin-top: 0 !important;
}

.content-f{
flex-direction: column;
align-items: center;
}

.content-step{
flex-direction: column;
}

.content-numbers{
flex-direction: column;
}

.buttons-investors {
text-align: center;
margin-left: 0% !important;
}

.content-input-welcome{
display: block;
}

.content-welcome{
display: block !important;
}

.txt-welcome{
width: 100%;
}

.content-legal-notice{
margin-top: 85%;
width: 85%;
}

.hands {
width: 35%;
}
.content-header {
display: grid !important;
width: 90%;
}

.style-menu{
margin-top: 5%;
}

.content-img-header-investors {
margin-top: 0%;
text-align: center;
}

.no-d-flex-tablet{
display: grid !important;
}

.pic_solution {
width: 100%;
border-radius: 40px;
height: 25em;
}

.content-block-solution {
align-self: center;
margin-top: 5%;
height: auto;
}

.btn-cta-h {
width: 40% !important;
font-size: clamp(1.1rem,1.1vw,1.7rem);
font-weight: 600;
}

.content-advantage {
padding-top: 0;
padding-bottom: 10%;
width: 100%;
}


.text-solution-right{
margin-bottom: 0;
}

.btn-cta-end {
background: #FFCD3B;
color: black;
letter-spacing: 0px;
width: 46% !important;
margin-top: 3% !important;
height: 3em;
border-radius: 4em;
font-size: clamp(1.1rem, 1.1vw, 1.7rem);
border: none;
margin-left: 27%;
margin-bottom: 5%;
}

.section-concept {
padding-bottom: 5%;
}

.block-tarif {
width: 80%;
padding-top: 5%;
padding-bottom: 5%;
margin-left: 10%;
height: auto;
margin-bottom: 10%;
}

.screen {
width: 90%;
margin-left: 10%;
padding-bottom: 0%;
}

.menu-burger{
margin-top: 3%;
}

.mobile-menu.show-menu {
transform: translateY(0);
animation: slideDown 0.8s;
padding-top: 10%;
padding-bottom: 5%;
}

.home-btn-menu-mobile{
width: 25%;
}

.content-icons {
width: 25%;
margin: 0 auto;
margin-bottom: 5%;
}

.content-footer {
font-size: clamp(1.1rem,1.1vw,1.7rem);
padding-top: 5%;
margin-left: 5%;
width: 90%;
}

.form-s {
margin: 0 auto;
width: auto;
margin-left: -40%;
}

.input-container input {
flex-basis: auto;
width: 65%;
height: 3em;
font-size: 20px;
}

.input-container{
margin-bottom: 5%;
}

.hr-footer {
width: 70%;
margin-left: 12%;
}

.text-footer {
margin-left: 12%;
width: 70%;
}

.pitchersales {
font-weight: 100;
font-size: 14px;
margin-left: -4%;
}

.selected-button-i {
width: 64% !important;
margin-bottom: 0%;
font-size: clamp(0rem,2.5vw,4rem);
}

.investors-btn-menu-mobile {
width: 25%;
}

.btn-cta-i {
width: 40% !important;
}

.content-block-i {
width: 95%;
}

.block-i {
width: 48% !important;
}

.img-i-4 {
width: 20%;
margin-top: 20%;
}

.img-i-j {
margin-top: 20%;
}

.img-i {
margin-top: 20%;
}

.consultant-btn-menu-mobile {
width: 25%;
}

.selected-button-c {
width: 45% !important;
height: 2.8em;
font-size: clamp(0rem,2.5vw,4rem);
display: block;
}

.icon-btn-menu{
display: none;
}

.btn-cta-c {
width: 40% !important;
}

.content-input-welcome{

}

.content-header {
display: grid !important;
width: auto;
}

.pic-welcome {
width: 100%;
max-width: 100%;
}

.btn-welcome {
margin-left: 0 !important;
}

.content-text-welcome {
max-width: 85%;
align-self: end;
margin: 0 auto;
padding-top: 6%;
}
}

/*IPAD AIR*/
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {

    .block-legal-notice{
        margin-top: -90%;
    }

    .content-slogan{
        flex-direction: column;
    }

    .Content-box {
        width: 100%;
        margin-top: 10%;
    }

    .big-title-investors {
        padding-top: 10%;
    }

    .text-banner{
        width: 80%;
        font-size: clamp(1.3rem, 1.1vw, 2rem) !important;
    }

    .content-love{
        margin-top: 8%;
        margin-bottom: 8%;
    }

    .content-expert-global {
        flex-direction: column;
        margin-left: 0;
        padding-left: 0;
    }

    .content-expert {
        width: 70%;
    }

    .content-join {
        height: 30em;
    }

    .content-step {
        flex-direction: column;
        margin-right: 0;
        padding-right: 0;
    }

    .txt-step {
        margin-left: 0!important;
    }

    .text-step{
        width: 100% !important;
        margin-left: 0% !important;
        margin-top: 5% !important;
        margin-bottom: 5% !important;
    }

    .btn-com-f {
        width: 50%;
        font-size: 18px;
    }

    .content-numbers {
        margin-left: 0;
    }

    .content-items {
        width: 60%;
        text-align: center;
    }

    .text-banner-content {
        width: 100%;
        font-size: clamp(1.3rem, 1.1vw, 2rem) !important;
    }

    .content-header{
        height: auto;
    }

    .content-carousel {
        width: 50%;
        text-align: center;
        height: 5em;
        padding-left: 0;
        justify-content: space-between;
        margin-top: 10%;
        margin-bottom: 10%;
        margin-left: 5%;

    }

    .img-constat {
        height: 20em;
    }

    .txt-constat {
        width: 90%;
        margin-left: 5%;
        font-size: clamp(1.3rem, 1.1vw, 2rem) !important;
    }

    .border-icons-f{
        padding: 20%;
        margin-top: 30%;
        display: none;
    }

    .content-f {
        width: 90%;
        margin-left: 5%;
        flex-direction: column;
        align-items: center;
        font-size: clamp(1.3rem, 1.1vw, 2rem) !important;
    }

    .content-icons-f{
        width: 20%;
    }

    .title-expert{
        text-align: center;
    }

    .title-community {
        margin-top: 0;
        text-align: center;
    }

    .btn-com-2 {
        width: 50%;
        font-size: clamp(1.3rem, 1.1vw, 2rem) !important;
        margin: 0 auto;
        margin-bottom: 10%;
    }

    .img-step{
        width: 90%;
        margin-left: 5%;
    }

    .text-step{
        margin-top: 10%;
        margin-bottom: 10%;
        text-align: center;
        width: 80% !important;
        font-size: clamp(1.3rem, 1.1vw, 2rem) !important;
        margin-left: 10% !important;
    }

    .title-love{
        width: 50%;
        margin-left: 25%;
    }

    .first-love{
        flex-direction: column;
    }

    .btn-love{
        text-align: center;
    }

    .second-love{
        flex-direction: column;
    }

    .btn-love{
        font-size: clamp(1.3rem, 1.1vw, 2rem) !important;
    }

    .btn-banner-join{
        font-size: clamp(1.3rem, 1.1vw, 2rem) !important;
        width: 100%;
        height: 3.5em;
        margin-top: 5%;
    }

    .title-content{
        width: 60%;
        margin: 0 auto;
        padding-bottom: 5%;
    }

    .txt-solutions-c {
        font-size: clamp(0rem,2.5vw,4rem);
    }

    .content-headband{
        margin-top: 2%;
    }
    .big-title-investors {
        font-size: clamp(3rem, 3.2vw, 7rem);
        line-height: 60px;
        margin: 0 auto;
        padding-top: 35%;
        width: 65%;
    }

    .mix-btn {
        width: 45% !important;
        margin: 0 auto;
        margin-top: 5%;
    }

    .content-slogan {
        margin-top: 0;
    }

    .title-slogan{
        flex-direction: column;
    }

    .buttons-investors{
        flex-direction: column;
    }

    .content-header{
        width: 100%;
        margin-left: 0;
        padding-left: 0;
    }

    .subtitle-header {
        font-size: clamp(0rem,2.5vw,4rem);
        margin-top: 5%;
        width: 100%;
    }

    .menu-burger {
        display: block;
    }

    .text-concept {
        width: 80%;
        font-size: clamp(0rem,1.9vw,1.2rem);
        margin-left: 10%;
    }

    .title-offers {
        font-size: clamp(1rem,3vw,3rem);
    }

    .big-title-solution{
        text-align: left;
        font-size: clamp(1rem,3vw,3rem);
    }

    .content-block-solution {
        margin-left: 0;
    }

    .mini-title-solution {
        text-align: left;
        margin-left: 0%;
    }

    .big-title-solution {
        margin-left: 0;
        font-size: clamp(0rem,2.5vw,4rem);
        width: 100%;
    }

    .text-solution-right {
        margin-left: 0;
        font-size: clamp(0rem,2.5vw,4rem);
    }

    .text-solution-left{
        margin-left: 0;
        font-size: clamp(0rem,2.5vw,4rem);
        width: 100%;
    }

    .pic_solution {
        grid-row: 1;
    }

    .text-i-1 {
        font-size: clamp(0rem,2.5vw,4rem);
    }

    .img-i-p {
        margin-top: 20%;
    }

    .img-i-t {
        margin-top: 20%;
    }

    .img-i-b {
        margin-top: 10%;
    }

    .pic-header {
        display: none;
    }

    .title-com{
        font-size: clamp(0rem,2.5vw,4rem);
    }

    .txt-com{
        font-size: clamp(0rem,2.5vw,4rem);
    }

    .content-community {
        width: 100%;
        margin-left: 10%;
    }

    .text-com {
        width: 100%;
    }

    .btn-com {
        width: 60% !important;
    }

    .small-logo {
        display: none;
    }

    .logo-s {
        display: block;
        width: 10%;
        margin: 0 auto;
    }

    .title-subscribe {
        font-size: clamp(0rem,2.5vw,4rem);
    }

    .subtitle-subscribe {
        text-align: center;
        font-size: clamp(0rem,2.5vw,4rem);
        width: 90%;
    }

    .content-input-welcome {
        margin-left: 0%;
    }

    .input-container input {
        flex-basis: 100%;
        height: 3em;
        font-size: clamp(0rem, 2.5vw, 4rem);
    }

    .input-container button {
        flex-basis: 10%;
    }

    .style-menu {
        margin-left: 5%;
        width: 90%;
    }

    .join-us {
        font-size: clamp(0rem,1.6vw,1.2rem);
    }

    .content-menu-desktop {
        max-width: 70%;
    }

    .btn-menu-subscribe-h, .btn-menu-subscribe-i, .btn-menu-subscribe-c {
        font-size: clamp(0rem,1.6vw,1.2rem) !important;
    }

    .join-us-investors, .join-us-consultant {
        font-size: clamp(0rem,1.6vw,1.2rem);
    }

    .txt-w {
        font-size: clamp(0rem,2.2vw,4rem);
        width: 100%;
        margin-left: 0%;
        margin-bottom: 5%;
    }

    .small-title-w {
        width: 80%;
        margin-left: 10%;
        margin-top: 0;
        color: #271350;
        font-size: clamp(0rem,1.9vw,1.2rem);
        text-align: left;
    }

    .content-block-offers {
        display: block;
    }

    .gift {
        display: none;
    }

    .title-step {
        font-size: clamp(0rem,2.5vw,4rem);
        margin-top: 5%;
        margin-left: 10%;
    }

    .txt-solutions {
        margin-left: 5%;
        font-size: clamp(0rem,2.5vw,4rem);
        width: 80%;
        padding-left: 5%;
    }

    .btn-cta-end {
        margin-left: 10%;
    }

    .small-txt-offers {
        font-size: clamp(0rem,2.5vw,4rem);
    }

}

@media only screen and (min-width: 834px) and (max-width: 1024px) {
    /* Styles for iPad Pro 11" and iPad Air Portrait */
    .content-slogan{
        flex-direction: column;
    }
}


/*TABLETTE PAYSAGE*/
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (orientation: landscape) {

    .content-headband {
        width: 100%;
        margin-top: 35%;
    }

    .Content-box {
        width: 100%;
        margin-top: 10%;
    }

    .content-legal-notice {
        margin-top: 5%;
        width: 70%;
    }

    .gift{
        display: none;
    }

    .big-title-investors {
        margin-top: 10%;
    }

    .subtitle-header {
        font-size: clamp(0rem, 1.5vw, 1.5rem);
    }

    .btn-menu-subscribe-i, .selected-button-i, .btn-menu-subscribe-h, .btn-menu-subscribe-c{
        font-size: clamp(0rem, 1.5vw, 1.5rem);
    }

    .join-us-investors,  .join-us, .join-us-consultant{
        font-size: clamp(0rem, 1.4vw, 1.5rem);
    }

    .content-menu-desktop {
        justify-content: space-between;
        max-width: 80%;
    }

    .selected-button-i, .selected-button, .selected-button-c{
        width: 50% !important;
        font-size: clamp(0rem, 1.7vw, 1.5rem);
        height: 3em;
    }

    .text-concept{
        line-height: 35px;
        font-size: clamp(0rem, 1.5vw, 1.5rem);
    }

    .small-title-w{
        font-size: clamp(0rem, 1.5vw, 1.5rem);
    }

    .btn-cta-c {
        width: 25% !important;
        font-size: clamp(0rem, 1.7vw, 1.5rem);
    }

    .content-adv-c {
        margin-top: 0%;
    }

    .pic_solution {
        grid-row: 1;
    }

    .content-block-solution {
        margin-left: 0;
    }

    .mini-title-solution {
        margin-left: 0%;
    }

    .big-title-solution {
        margin-left: 0;
    }

    .text-solution-right {
        margin-bottom: 0;
        margin-left: 0;
        font-size: clamp(0rem, 1.5vw, 1.5rem);
    }

    .text-solution-left{
        font-size: clamp(0rem, 1.5vw, 1.5rem);
        width: 90%;
    }

    .txt-solutions-c{
        font-size: clamp(0rem, 1.5vw, 1.5rem);
    }

    .btn-cta-end-c {
        font-size: 22px;
        font-size: clamp(0rem, 1.7vw, 1.5rem);
    }

    .form-s {
        margin-left: -50%;
    }

    .input-container input {
        flex-basis: auto;
        width: 40%;
        height: 3.3em;
        font-size: clamp(0rem, 1.5vw, 1.5rem);
    }

    .logo-s {
        display: block !important;
        margin: 0 auto;
    }

    .small-logo{
        display: none;
    }

    .content-subscribe {
        margin-bottom: 0%;
    }

    .content-footer {
        margin-left: 0%;
    }

    .txt-solutions {
        width: 80%;
        font-size: clamp(0rem, 1.5vw, 1.5rem);
    }

    .btn-cta-end {
        font-size: clamp(1.1rem, 1.1vw, 1.7rem);
        margin-left: 5%;
        margin-bottom: 0%;
        width: 30% !important;
    }

    .content-block-offers {
        display: block;
    }

    .small-txt-offers {
        font-size: clamp(0rem, 1.5vw, 1.5rem);
    }

    .block-tarif {
        width: 70%;
        margin-left: 15%;
    }

    .yellow-check {
        margin-top: 2%;
    }

    .img-i-p {
        width: 30%;
        margin-top: 20%;
    }

    .img-i-t {
        width: 25%;
        margin-top: 15%;
    }

    .img-i-b {
        width: 30%;
        margin-top: 10%;
    }

    .txt-com {
        font-size: clamp(0rem, 1.5vw, 1.5rem);
    }

    .btn-com {
        font-size: clamp(0rem, 1.5vw, 1.5rem);
        width: 85% !important;
    }

    .btn-offers{
        font-size: clamp(0rem, 1.5vw, 1.5rem);
        margin-top: 0;
    }

}


/*typeform*/

.typeform-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.typeform-container {
    position: relative;
    width: 80%;
    height: 80%;
    background-color: white;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10000;
}

.cross-form{
    width: 2%;
    margin-left: 90%;
    margin-top: 5%;
}


.entity-selector {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    margin-top: -10%;
}

.entity-selector button {
    margin: 0 5px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background: white;
}

.entity-selector button:hover{
    color: #9A41FF;
}

.entity-selector button.active {
    background-color: #9A41FF;
    color: white;
}

.chat-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
}

.chat-list {
    width: 90%;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    margin-top: 5%;
}

.chat-message {
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
}

.chat-message.user {
    background-color: #F5F5F5;
    align-self: flex-end;
}

.chat-message.bot {
    background-color: #EEE;
    align-self: flex-start;
}

form {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10%;
    margin-top: 5%;
    background: white;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    border-radius: 30em;

}

.btn-ia{
    width: 80%;
    margin-top: 10%;
    background: white;
    border-radius: 3em;
    margin-bottom: 5%;
}

.content-ia .chat-container {
    height: 340px;
    overflow-y: auto;
    padding-right: 15px;
}

form input {
    width: 80%;
    padding: 15px;
    border: none;
}

form button {
    padding: 5px;
    border: none;
    background-color: #5e5dcd;
    color: white;
    border-radius: 35em;
    cursor: pointer;
}

.title-ai{
    text-align: center;
    margin-top: 5%;
    font-size: 2.3em;
}

.sidebar {
    flex: 1 1;
    padding: 20px;
    width: 20%;
    background: #9A41FF !important;
    color: white;
}

.content-ia{
    background: white;
    margin: 0 auto;
    margin-top: 10%;
    width: 70%;
    height: 25em;
    margin-bottom: 15%;
}

.main-content{
    width: 80%;
}

/*Timeline*/

/* Conteneur de la timeline */
.timeline-container {
    position: relative;
    width: 100%;
    height: 1200px; /* Ajuste selon la longueur de la page */
    margin: 0 auto;
    margin-top: 10%;
}

/* Ligne de la timeline */
/* Ligne de la timeline */
.timeline-line {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    background-color: #271350;
    transition: height 0.3s ease;
    max-height: 1000px;
}


/* Points de la timeline */
.timeline-point {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 20px;
    background-color: #6200ea;
    border-radius: 50%;
    background: linear-gradient(90deg, rgba(212, 168, 255, 1) 21%, rgba(251, 166, 242, 1) 58%, rgba(218, 205, 255, 1) 100%);
}

/* Texte de la timeline */
.timeline-text {
    position: absolute;
    width: 15em;
    padding: 2em;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 22px;
    color: #271350;
    font-family: 'Glacial Indifference';
}

.timeline-text.left {
    left: calc(-950% - 250px);
    text-align: right;
}

.timeline-text.right {
    left: calc(50% + 30px);
    text-align: left;
}

.events-container {
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    padding: 20px;
    max-width: 85%;
    margin: 0 auto;
    margin-top: -5% !important;
}

.event-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.past-event .event-card img {
    filter: grayscale(100%); /* Grise l'image */
}


.event-date-tag {
    position: absolute;
    top: -10px;
    left: 20px;
    background: #271350;
    color: white;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 14px;
    z-index: 1;
}

.event-card {
    width: 100%;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.event-card img {
    width: 100%;
    height: 15em;
    object-fit: cover;
}

.event-title {
    margin-top: 15px;
    font-size: 18px;
    text-align: center;
    font-family: "Abril Fatface", serif;
}

@media (max-width: 768px) {
    .events-container {
        grid-template-columns: repeat(1, 1fr);
        margin-top: 10% !important;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .events-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

.content-header-event{
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 5%, rgba(252, 233, 255, 1) 24%, rgba(240, 197, 242, 1) 51%, rgba(212, 179, 254, 1) 91%);
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 8em;
    min-height:30vh;
}

